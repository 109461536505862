<!-- 詳細画面-->
<p-sidebar
  [(visible)]="detailsNav"
  position="right"
  styleClass="p-sidebar-md"
  blockScroll="false"
  [autoZIndex]="true"
  [showCloseIcon]="false"
>
  <p-scrollPanel styleClass="custombar">
    <!-- 詳細情報-->
    <app-generate-display-information></app-generate-display-information>

    <p-footer>
      <!-- 在庫管理ボタン表示領域 -->
      <p-table [value]="[{}]" styleClass="p-datatable-sm">
        <!-- 在庫管理タイトル -->
        <ng-template pTemplate="header">
          <tr class="p-col-2">
            <th colspan="2">在庫管理</th>
          </tr>
        </ng-template>
        <!-- 在庫管理ボタン出力箇所 -->
        <ng-template pTemplate="body">
          <tr>
            <td>
              <!-- 入庫登録 -->
              <button
                pButton
                pRipple
                type="button"
                label="入庫登録"
                (click)="inputStockRegist()"
                class="p-button-raised p-mr-2 button"
              ></button>
            </td>
            <td>
              <!-- 在庫調整 -->
              <button
                pButton
                pRipple
                type="button"
                label="在庫調整"
                (click)="inputAdjustStock()"
                class="p-button-raised p-mr-2 button"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <!-- 倉庫画像登録表示領域 -->
      <p-table [value]="[{}]" styleClass="p-datatable-sm">
        <!-- 倉庫画像登録タイトル -->
        <ng-template pTemplate="header">
          <tr class="p-col-2">
            <th>倉庫画像登録</th>
          </tr>
        </ng-template>
        <!-- 倉庫画像登録ボタン出力箇所 -->
        <ng-template pTemplate="body">
          <tr>
            <td>
              <!-- 画像登録 -->
              <p-fileUpload
                #fileUpload
                mode="basic"
                uploadIcon="pi pi-file-o"
                chooseLabel="画像登録"
                [auto]="true"
                customUpload="true"
                (uploadHandler)="uploadFile($event, fileUpload)"
                class="p-button-raised p-mr-2"
              >
              </p-fileUpload>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-footer>
  </p-scrollPanel>
</p-sidebar>

<!-- 入庫登録、在庫調整 -->
<app-stock (reloadID)="return($event)"></app-stock>
