/**
 * DB値定数
 */
/** 注文情報 */
// ステータス
export const ORDER_STATUS = {
  STATUS_ONE: '1',
  STATUS_TWO: '2',
  STATUS_THREE: '3',
  STATUS_FOUR: '4',
  STATUS_FIVE: '5',
  STATUS_SIX: '6',
  STATUS_NINE: '9',
};

/** 組織情報 */
// 組織レベル
export const DEPARTMENT_LEVEL = {
  LEVEL_GENERAL: '0',
  LEVEL_MANAGEMENT: '1',
};
// 組織種別
export const DEPARTMENT_TYPE = {
  TYPE_FORMER: '1',
  TYPE_DESTINATION: '2',
  TYPE_OFFICE: '3',
  TYPE_OTHER: '9',
};
