import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { BREADCRUMB_ORDER } from './breadcrumb-constants';
import { API_URL_WORK_ORDER } from 'manager/http-constants_key';
import { WORK_ORDER_TEMPLATE } from 'manager/template-constant';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['../order.component.scss'],
})

/**
 * 発注管理_パンくずリスト
 */
export class BreadcrumbComponent implements OnInit {
  // パンくずリスト
  items: MenuItem[] = [
    {
      // 販促資材リストへ遷移
      label: BREADCRUMB_ORDER.SELECT_ITEM,
      routerLink: '/pages/order/item-search',
    },
    {
      // 届先リストへ遷移
      label: BREADCRUMB_ORDER.SELECT_DELIVERY,
      routerLink: '/pages/order/delivery-search',
    },
    {
      // 選択リストへ遷移
      label: BREADCRUMB_ORDER.SELECTED,
      routerLink: '/pages/order/cart',
    },
    {
      // 数量へ遷移
      label: BREADCRUMB_ORDER.QUANTITY_INPUT,
    },
  ];

  // 数量ボタン表示フラグ
  quantityButtonFlag: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    // 選択ボタンが押下されたか否か
    if (location.pathname == '/pages/order/cart') {
      // 数量ボタン表示フラグをONにする
      this.quantityButtonFlag = true;
    }
  }

  // パンくずリストのクリックイベント
  public itemClicked(event) {
    // 数量ボタンが押下されたか否か
    if (
      BREADCRUMB_ORDER.QUANTITY_INPUT == event.item.label &&
      location.pathname != '/pages/order/quantity'
    ) {
      // 数量ボタンが押下 かつ
      // 数量ページ以外の場合

      // 注文情報(WORK)登録
      this.insertWorkOrder();
    }
  }

  /**
   * 選択リスト情報で注文情報(WORK)登録
   */
  protected insertWorkOrder() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // 注文情報(WORK)登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER, {
        TemplateID: WORK_ORDER_TEMPLATE.INPUT_TEMPLATE_ID,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // レスポンスを判定
        if (this.messageData.responseToastMessage(response)) {
          // レスポンスが正常終了の場合

          // 発注管理_数量へ遷移
          this.router.navigate(['pages/order/quantity']);
        }
      });
  }
}
