import { Component, OnInit, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_ORDER_DELIVERIE,
  API_URL_SEARCH_ORDER_DELIVERIE,
  API_URL_WORK_ORDER_DELIVERIE,
} from 'manager/http-constants_key';
import { Router } from '@angular/router';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { DELIVERIES_SEARCH_TEMPLATE } from 'manager/template-constant';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import { HEADER_LIST_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { DELIVERISE_SEARCH_CONSTANT } from './constant';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-delivery-search',
  templateUrl: './delivery-search.component.html',
  styleUrls: ['../order.component.scss', './delivery-search.component.scss'],
})

/**
 * 発注管理_届先選択
 */
export class DeliverySearchComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** CSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER_DELIVERIE;

  // 検索項目生成テンプレートID
  public searchTemplateId: number =
    DELIVERIES_SEARCH_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // カート
  cartSelected: any[] = new Array();

  // ページ数
  pages: number = 0;

  /* csv用プロパティ */
  // ヘッダー情報格納先(CSV用)
  csvColumnOrder: any[] = new Array();

  // 検索結果一覧格納先(CSV用)
  csvList: any[] = new Array();

  // CSVダウンロード可能フラグ
  csvLoading: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(DELIVERIES_SEARCH_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    /* ヘッダー情報取得処理(CSV用) */
    this.dbOperationService
      .getHeaderList(DELIVERIES_SEARCH_TEMPLATE.CSV_TEMPLATE_ID)
      .subscribe((response) => {
        this.csvColumnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 届け先マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_DELIVERIE,
        DELIVERIES_SEARCH_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 届先マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    /* 届け先マスタ一覧取得処理(CSV用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_DELIVERIE,
        DELIVERIES_SEARCH_TEMPLATE.CSV_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 届先マスタ一覧情報のJSONをオブジェクトに格納する
          this.csvList = response.body;
        } else {
          this.csvList = [];
        }

        // CSVダウンロードフラグをOFF(ロード終了)
        this.csvLoading = false;
      });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER_DELIVERIE;
    inputGenerateDisplay.templateId =
      DELIVERIES_SEARCH_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * カート追加
   */
  protected addCart() {
    // カートが選択されているか否か
    if (!this.cartSelected) {
      // カートが選択されていない場合
      return;
    }

    // カート登録用に整形
    const code = this.commonService.createArrayGetArrayObject(
      this.cartSelected,
      this.columnOrder[0].field
    );

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00012),
      })
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER_DELIVERIE, {
        delivery_id: code.join(CONSTANT.COMMA),
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // レスポンスを判定
        if (this.messageData.responseToastMessage(response)) {
          // レスポンスが正常終了の場合

          // 発注管理_選択リストへ遷移
          this.router.navigate(['pages/order/cart']);
        }
      });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      DELIVERISE_SEARCH_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // ヘッダー情報を作成
    const header = this.commonService.createArrayGetArrayObject(
      this.csvColumnOrder,
      HEADER_LIST_API_CONSTANT.HEADER
    );

    // CSVファイル出力
    this.exportFileComponent.exportCsv(fileName, header, this.csvList);
  }
}
