/**
 * 画面用共通定数
 */
/** 定数 */
export const CONSTANT = {
  // ハイフン
  HYPHEN: '-',
  // アンダーバー
  UNDERBAR: '_',
  // カンマ
  COMMA: ',',
  // コロン
  COLON: ':',
  // ピリオド
  PERIOD: '.',
  // アンパサンド
  AMPERSAND: '&',
  // ダブルクォーテーション
  DOUBLE_QUOTATION: '"',
  // イコール
  EQUAL: '=',
  // 空文字
  EMPTY_STRING: '',
  // 空文字(1文字)
  EMPTY_ONE_STRING: ' ',
};

/** 数字 */
export const NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
};

/** 日付形式 */
export const DATE_FORMAT = {
  // 年月日(ハイフン)
  DATE_HYPHEN: 'yyyy-MM-dd',
  // 年月日時分秒(ハイフン)
  DATETIME_HYPHEN: 'yyyy-MM-dd-HHmmss',
  // 年月日(アンダーバー)
  DATE_UNDERBAR: 'yyyy_MM_dd',
  // 年月日時分秒
  DATETIME: 'yyyyMMddHHmmss',
};

/** ファイル拡張子 */
export const FILE_EXTENSION = {
  // CSVファイル
  CSV: '.csv',
  // TSVファイル
  TSV: '.tsv',
};

/** 画像拡張子 */
export const IMAGE_EXTENSION = {
  // JPG画像
  JPG: '.jpg',
  // JPEG画像
  JPEG: '.jpeg',
  // PNG画像
  PNG: '.png',
  // GIF画像
  GIF: '.gif',
};

/** 画面定数 */
export const SCREEN_CONSTANT = {
  LINE_CODE: '<br>',
};

/** ファイル定数 */
export const FILE_CONSTANT = {
  LINE_CODE: '\r\n',
  TAB: '\t',
};

/** 文字コード */
export const CHARACTER_CODE = {
  SJIS: 'Shift_JIS',
};

/** ローディングキー */
export const LOADING_KEY = {
  // ヘッダーメニュー
  HEADER_MENU: 'HEADER_MENU',
  // TOP画面.お知らせ
  TOP_NOTICE: 'TOP_NOTICE',
  // アイテムアダプター詳細
  GENERATE_DISPLAY: 'GENERATE_DISPLAY',
  // アイテムアダプター入力
  GENERATE_INPUT: 'GENERATE_INPUT',
  // アイテムアダプター検索
  GENERATE_SEARCH: 'GENERATE_SEARCH',
  // データ更新
  DATA_UPDATE: 'DATA_UPDATE',
  // 削除
  DELETE: 'DELETE',
  // キャンセル
  CANCEL: 'CANCEL',
  // ファイルアップロード
  FILE_UPLOAD: 'FILE_UPLOAD',
  // ファイルダウンロード
  FILE_DOWNLOAD: 'FILE_DOWNLOAD',
};
