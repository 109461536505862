<!-- 発注管理リスト -->
<app-breadcrumb></app-breadcrumb>

<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <p-table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]"
    styleClass="p-datatable-striped order-datatable"
    selectionMode="multiple"
    [(first)]="pages"
    [(selection)]="cartSelected"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
          <!-- カート追加 -->
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-arrow-circle-up"
            (click)="addCart()"
            class="p-mr-2"
            pTooltip="カート追加"
            tooltipPosition="bottom"
          ></button>
        </div>
        <div>
          <!-- 全件csv出力-->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            [disabled]="csvLoading"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let column of columnOrder">
          <th [pSortableColumn]="column.field">
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr [pSelectableRow]="searchResults">
        <!-- チェックボックス -->
        <td>
          <p-tableCheckbox
            disabled="false"
            [value]="searchResults"
          ></p-tableCheckbox>
        </td>
        <ng-container
          *ngFor="let column of columnOrder; index as i"
          [ngSwitch]="true"
        >
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[column.field])"
            *ngIf="0 == i; else hideContent"
          >
            <label>
              <u>{{ searchResults[column.field] }}</u>
            </label>
          </td>

          <!-- 一覧項目値表示 -->
          <ng-template #hideContent [ngSwitch]="column?.input_type">
            <td *ngSwitchCase="'NUMBER'">
              {{ searchResults[column.field] | number }}
            </td>
            <td *ngSwitchDefault>
              {{ searchResults[column.field] }}
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult($event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- CSV出力 -->
<app-export-file></app-export-file>
