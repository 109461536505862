/**
 * 画面用メッセージ定数
 */
/** 権限チェックエラー */
export const ROUTING_ERROR = {
  // 権限情報設定無し
  ROUTING_NONE: '権限情報が設定されておりません',
  // 権限パスが不正
  ROUTING_AUTHORITY_PATH_ERROR: 'URLが不正です',
  // 権限が不正
  ROUTING_AUTHORITY_ERROR: 'ログインユーザ情報の権限が不正です',
};

/** HTTPステータスエラー */
export const HTTP_ERROR = {
  // 401
  RESPONSE_NONE: 'APIレスポンスが存在しません',
  // 400
  HTTP_400: 'APIに不正なリクエストが行われました',
  // 401
  HTTP_401: '認証に失敗しました',
  // 403
  HTTP_403: '不正なリクエストが送信されました',
  // 404
  HTTP_404: 'APIが存在しません',
  // 412
  HTTP_412: 'システム稼働時間外となります',
  // 422
  HTTP_422: 'API取得失敗',
  // 500
  HTTP_500: 'API更新失敗',
  // ERROR
  ERROR: 'APIに予期しないエラーが発生しました',
};

/** メッセージコード */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE_CODE = {
  // 完了
  T00001: 'T00001',
  // 完了しました
  T00002: 'T00002',
  // 反映まで5分かかります
  T00003: 'T00003',
  // {0}:{1}
  T00004: 'T00004',
  // 出力しますか？
  T00005: 'T00005',
  // 情報が選択されていません
  T00006: 'T00006',
  // 出力する情報を1件以上選択して下さい
  T00007: 'T00007',
  // 出力情報が存在しませんでした
  S00001: 'S00001',
  // 入力確認中
  S00002: 'S00002',
  // 検索中
  S00003: 'S00003',
  // 一覧取得中
  S00004: 'S00004',
  // ファイル確認中
  S00005: 'S00005',
  // 注文確認中
  S00006: 'S00006',
  // 登録完了しました
  I00001: 'I00001',
  // 複製完了しました
  I00002: 'I00002',
  // 画像登録完了しました
  I00003: 'I00003',
  // 注文内容は<br>お間違え御座いませんでしょうか？
  I00004: 'I00004',
  // 注文が完了しました
  I00005: 'I00005',
  // ファイル情報の登録は<br>お間違え御座いませんでしょうか?
  I00006: 'I00006',
  // ファイル情報の登録が完了しました
  I00007: 'I00007',
  // 登録中
  I00008: 'I00008',
  // 複製中
  I00009: 'I00009',
  // ファイル登録中
  I00010: 'I00010',
  // 注文中
  I00011: 'I00011',
  // カート追加中
  I00012: 'I00012',
  // ファイル登録完了しました
  I00013: 'I00013',
  // 編集完了しました
  U00001: 'U00001',
  // 更新完了しました
  U00002: 'U00002',
  // キャンセルしました
  U00003: 'U00003',
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: 'U00004',
  // 情報出力が完了しました
  U00005: 'U00005',
  // 編集中
  U00006: 'U00006',
  // 更新中
  U00007: 'U00007',
  // 情報出力中
  U00008: 'U00008',
  // キャンセル中
  U00009: 'U00009',
  // 削除完了しました
  D00001: 'D00001',
  // {0}:{1}<br>削除してもよろしいですか？
  D00002: 'D00002',
  // {0}<br>削除してもよろしいですか？
  D00003: 'D00003',
  // 削除中
  D00004: 'D00004',
  // ログイン
  N80000: 'N80000',
  // ようこそ、{0}さん
  N80001: 'N80001',
  // ログアウト
  N80002: 'N80002',
  // ご利用ありがとうございました
  N80003: 'N80003',
  // ログアウトしました
  N90000: 'N90000',
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001: 'N90001',
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002: 'N90002',
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003: 'N90003',
  // 失敗
  E00001: 'E00001',
  // 失敗しました
  E00002: 'E00002',
  // エラー
  E00003: 'E00003',
  // 登録失敗しました
  E00004: 'E00004',
  // 削除失敗しました
  E00005: 'E00005',
  // キャンセル失敗しました
  E00006: 'E00006',
  // status:{0} body:{1} url:{3}
  E00007: 'E00007',
  // status:{0} message:{1}
  E00008: 'E00008',
  // 画像登録失敗しました
  E00009: 'E00009',
  // 画像拡張子は{0}のみとなります
  E00010: 'E00010',
  // 画像サイズは{0}以下までとなります
  E00011: 'E00011',
  // ファイル読み込みが失敗しました
  E00012: 'E00012',
  // ファイル拡張子は{0}のみとなります
  E00013: 'E00013',
  // 注文数量を入力してください
  E00014: 'E00014',
  // {0}API URLが存在しません
  E80000: 'E80000',
  // 異常発生
  E80001: 'E80001',
  // システム管理者へお問い合わせください
  E80002: 'E80002',
  // 警告
  E80003: 'E80003',
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: 'E90000',
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001: 'E90001',
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002: 'E90002',
};

/** メッセージ */
// 先頭文字
// T 下記のいずれにも一致しない
// S DB検索系のメッセージ
// I DB登録系メッセージ
// U DB更新系メッセージ
// D DB削除系メッセージ
// N 通常メッセージ
// E エラーメッセージ
export const MESSAGE = {
  // 完了
  T00001: '完了',
  // 完了しました
  T00002: '完了しました',
  // 反映まで5分かかります
  T00003: '反映まで5分かかります',
  // {0}:{1}
  T00004: '{0}:{1}',
  // 出力しますか？
  T00005: '出力しますか？',
  // 情報が選択されていません
  T00006: '情報が選択されていません',
  // 出力する情報を1件以上選択して下さい
  T00007: '出力する情報を1件以上選択して下さい',
  // 出力情報が存在しませんでした
  S00001: '出力情報が存在しませんでした',
  // 入力確認中
  S00002: '入力確認中',
  // 検索中
  S00003: '検索中',
  // 一覧取得中
  S00004: '一覧取得中',
  // ファイル確認中
  S00005: 'ファイル確認中',
  // 注文確認中
  S00006: '注文確認中',
  // 登録完了しました
  I00001: '登録完了しました',
  // 複製完了しました
  I00002: '複製完了しました',
  // 画像登録完了しました
  I00003: '画像登録完了しました',
  // 注文内容は<br>お間違え御座いませんでしょうか？
  I00004: '注文内容は<br>お間違え御座いませんでしょうか？',
  // 注文が完了しました
  I00005: '注文が完了しました',
  // ファイル情報の登録は<br>お間違え御座いませんでしょうか?
  I00006: 'ファイル情報の登録は<br>お間違え御座いませんでしょうか?',
  // ファイル情報の登録が完了しました
  I00007: 'ファイル情報の登録が完了しました',
  // 登録中
  I00008: '登録中',
  // 複製中
  I00009: '複製中',
  // ファイル登録中
  I00010: 'ファイル登録中',
  // 注文中
  I00011: '注文中',
  // カート追加中
  I00012: 'カート追加中',
  // ファイル登録完了しました
  I00013: 'ファイル登録完了しました',
  // 編集完了しました
  U00001: '編集完了しました',
  // 更新完了しました
  U00002: '更新完了しました',
  // キャンセルしました
  U00003: 'キャンセルしました',
  // {0}:{1}<br>キャンセルしてもよろしいですか？
  U00004: '{0}:{1}<br>キャンセルしてもよろしいですか？',
  // 情報出力が完了しました
  U00005: '情報出力が完了しました',
  // 編集中
  U00006: '編集中',
  // 更新中
  U00007: '更新中',
  // 情報出力中
  U00008: '情報出力中',
  // キャンセル中
  U00009: 'キャンセル中',
  // 削除完了しました
  D00001: '削除完了しました',
  // {0}:{1}<br>削除してもよろしいですか？
  D00002: '{0}:{1}<br>削除してもよろしいですか？',
  // {0}<br>削除してもよろしいですか？
  D00003: '{0}<br>削除してもよろしいですか？',
  // 削除中
  D00004: '削除中',
  // ログイン
  N80000: 'ログイン',
  // ようこそ、{0}さん
  N80001: 'ようこそ、{0}さん',
  // ログアウト
  N80002: 'ログアウト',
  // ご利用ありがとうございました
  N80003: 'ご利用ありがとうございました',
  // ログアウトしました
  N90000: 'ログアウトしました',
  // ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します
  N90001:
    'ユーザのログイン有効期限が過ぎております<br>下記から再度ログインをお願い致します',
  // システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します
  N90002:
    'システム稼働時間外となります<br>システム稼働時間内に再度ログインをお願い致します',
  // ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します
  N90003:
    'ログイン認証時間が終了しました<br>下記から再度ログインをお願い致します',
  // 失敗
  E00001: '失敗',
  // 失敗しました
  E00002: '失敗しました',
  // エラー
  E00003: 'エラー',
  // 登録失敗しました
  E00004: '登録失敗しました',
  // 削除失敗しました
  E00005: '削除失敗しました',
  // キャンセル失敗しました
  E00006: 'キャンセル失敗しました',
  // status:{0} body:{1} url:{3}
  E00007: 'status:{0} body:{1} url:{2}',
  // status:{0} message:{1}
  E00008: 'status:{0} message:{1}',
  // 画像登録失敗しました
  E00009: '画像登録失敗しました',
  // 画像拡張子は{0}のみとなります
  E00010: '画像拡張子は{0}のみとなります',
  // 画像サイズは{0}以下までとなります
  E00011: '画像サイズは{0}以下までとなります',
  // ファイル読み込みが失敗しました
  E00012: 'ファイル読み込みが失敗しました',
  // ファイル拡張子は{0}のみとなります
  E00013: 'ファイル拡張子は{0}のみとなります',
  // 注文数量を入力してください
  E00014: '注文数量を入力してください',
  // {0}API URLが存在しません
  E80000: '{0}API URLが存在しません',
  // 異常発生
  E80001: '異常発生',
  // システム管理者へお問い合わせください
  E80002: 'システム管理者へお問い合わせください',
  // 警告
  E80003: '警告',
  // 不正なユーザとなります<br>下記から再ログインをお願い致します
  E90000: '不正なユーザとなります<br>下記から再度ログインをお願い致します',
  // ログインユーザに権限がございません<br>下記から再ログインをお願い致します
  E90001:
    'ログインユーザに権限がございません<br>下記から再度ログインをお願い致します',
  // 不正なリクエストが行われました<br>下記から再ログインをお願い致します
  E90002:
    '不正なリクエストが行われました<br>下記から再度ログインをお願い致します',
};
