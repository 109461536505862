import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GenerateInputFormComponent } from './generate-input-form/generate-input-form.component';
import { GenerateInputErrorMessageComponent } from './generate-input-error-message/generate-input-error-message.component';
import { GENERATE_INPUT_TYPE } from '../constant';
import { GenerateService } from '../../generate.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';

@Component({
  selector: 'app-generate-input-information',
  templateUrl: './generate-input-information.component.html',
  styleUrls: ['../generate-input.component.scss'],
})

/**
 * 入力情報
 */
export class GenerateInputInformationComponent implements OnInit {
  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  // 登録種別
  inputType: string;

  // REST APIエンドポイント
  endPoint: string;

  // 対象データのID
  pkeyId: string;

  constructor(
    private generateService: GenerateService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {}

  /**
   * 入力情報表示
   * @param inputType 登録種別（新規:new、編集:edit、複製:duplicate）
   * @param endPointInput 入力項目生成用エンドポイント
   * @param endPoint REST APIエンドポイント
   * @param templateId テンプレートID
   * @param pkeyId 対象データのID
   */
  public initial(
    inputType: string,
    endPointInput: string,
    endPoint: string,
    templateId: number,
    pkeyId: string
  ) {
    // 登録種別をプロパティに格納
    this.inputType = inputType;

    // エンドポイントをプロパティに格納
    this.endPoint = endPoint;

    // 対象IDをプロパティに格納
    this.pkeyId = pkeyId;

    // 入力エラーメッセージをクリア
    this.generateInputErrorMessageComponent.clear();

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      inputType,
      endPointInput,
      endPoint,
      templateId,
      pkeyId
    );
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }

  /**
   * 入力フォーム情報登録
   * @param generateInputInformation 入力フォーム情報
   */
  public insertGenerateInputInformation(generateInputInformation: any) {
    // 登録種別を判定
    if (
      GENERATE_INPUT_TYPE.NEW == this.inputType ||
      GENERATE_INPUT_TYPE.DUPLICATE == this.inputType
    ) {
      // 新規 or 複製の場合

      // 画面カスタムロードフラグをON(ロード中状態)
      this.loadingState.customLoadStart(
        new LoadData({
          loadingText: '',
          background_color: '',
          opacity: 0.3,
        }),
        LOADING_KEY.GENERATE_INPUT
      );

      // 入力フォームで新規データを作成
      this.generateService
        .insert(this.endPoint, generateInputInformation.value)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          // 入力レスポンスメッセージを表示
          if (
            this.messageData.generateInputResponseMessage(
              this.inputType,
              response
            )
          ) {
            // 正常終了の場合

            // 更新対象IDにnullをセット
            this.reloadID.emit(null);
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.generateInputFormComponent.resetFlag();
          }
        });
    } else if (GENERATE_INPUT_TYPE.EDIT == this.inputType) {
      // 編集の場合

      // 画面カスタムロードフラグをON(ロード中状態)
      this.loadingState.customLoadStart(
        new LoadData({
          loadingText: '',
          background_color: '',
          opacity: 0.3,
        }),
        LOADING_KEY.GENERATE_INPUT
      );

      // 入力フォームで既存データを更新
      this.generateService
        .update(this.endPoint, this.pkeyId, generateInputInformation.value)
        .subscribe((response) => {
          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd(LOADING_KEY.GENERATE_INPUT);

          // 入力レスポンスメッセージを表示
          if (
            this.messageData.generateInputResponseMessage(
              this.inputType,
              response
            )
          ) {
            // 正常終了の場合

            // 更新対象IDにプライマリキーをセット
            this.reloadID.emit(this.pkeyId);
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.generateInputFormComponent.resetFlag();
          }
        });
    }
  }
}
