/**
 * API URL(開発用)
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/uploadimage/';
// ファイル登録
export const API_URL_UPLOAD_CSV =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/uploadcsv/';
// ファイル情報登録
export const API_URL_REGIST_DATA =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/registdata/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/runcount/';
// 辞書値取得
export const API_URL_DICVALUES =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/dicvalues/';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT =
  'https://i37fftwfzc.execute-api.ap-northeast-1.amazonaws.com/dev/output/';

/**
 * 画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/informations2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/informations/';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/searchinformations/';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/inputinformations/';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/users/';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/searchusers/';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/inputusers/';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/searchbizdays/';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/inputbizdays/';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/departments/';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/searchdepartments/';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT =
  'https://o2p3bl4200.execute-api.ap-northeast-1.amazonaws.com/dev/inputdepartments/';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK =
  'https://267qiitw79.execute-api.ap-northeast-1.amazonaws.com/dev/stock/';

// 届先マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/searchdeliveries/';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/inputdeliveries/';

// 販促資材マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/items/';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/searchitems/';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM =
  'https://lowsw5c8mh.execute-api.ap-northeast-1.amazonaws.com/dev/inputitems/';

// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/orders/';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/searchorders/';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/inputorders/';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/userdepartments/';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/workorders/';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/status';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/cancel/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/inputworkorders/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/inputorderitems/';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/orderdeliveries/';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderdeliveries/';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/workorderdeliveries/';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/workitemstockinfo/';
// 発注管理_販促資材／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_ITEM =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderitems/';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM =
  'https://e0tg2z3hqc.execute-api.ap-northeast-1.amazonaws.com/dev/workorderitems/';
