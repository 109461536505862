import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';
import { NoticeComponent } from './top/notice/notice.component';
import { NoticeListComponent } from './top/notice/notice-list/notice-list.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { ItemDetailComponent } from './master/item/item-detail/item-detail.component';
import { StockComponent } from './master/item/stock/stock.component';
import { UserComponent } from './master/user/user.component';

// ORDER
import { BreadcrumbComponent } from './order/breadcrumb/breadcrumb.component';
import { CartComponent } from './order/cart/cart.component';
import { CartDeliveryComponent } from './order/cart/cart-delivery/cart-delivery.component';
import { CartItemComponent } from './order/cart/cart-item/cart-item.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { OrderRegisterComponent } from './order/order-register/order-register.component';
import { QuantityComponent } from './order/quantity/quantity.component';
import { OrderHistory1StageComponent } from './order/order-history-1-stage/order-history-1-stage.component';
import { UpdateStatus1StageComponent } from './order/order-history-1-stage/update-status-1-stage/update-status-1-stage.component';
import { OrderHistory2StageComponent } from './order/order-history-2-stage/order-history-2-stage.component';
import { UpdateStatus2StageComponent } from './order/order-history-2-stage/update-status-2-stage/update-status-2-stage.component';

// SECRETARIAT
import { RegisterShippingResultComponent } from './secretariat/register-shipping-result/register-shipping-result.component';
import { OutputComponent } from './secretariat/output/output.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

@NgModule({
  declarations: [
    // TOP
    TopComponent,
    NoticeComponent,
    NoticeListComponent,

    // MASTER
    BizdayComponent,
    DeliveryComponent,
    DepartmentComponent,
    InformationComponent,
    ItemComponent,
    ItemDetailComponent,
    StockComponent,
    UserComponent,

    // ORDER
    BreadcrumbComponent,
    CartComponent,
    CartDeliveryComponent,
    CartItemComponent,
    DeliverySearchComponent,
    ItemSearchComponent,
    OrderRegisterComponent,
    QuantityComponent,
    OrderHistory1StageComponent,
    UpdateStatus1StageComponent,
    OrderHistory2StageComponent,
    UpdateStatus2StageComponent,

    // SECRETARIAT
    RegisterShippingResultComponent,
    OutputComponent,

    // MESSAGE
    MessageComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
})
export class PagesModule {}
