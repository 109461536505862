import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL_ORDER } from 'manager/http-constants_key';
import { ORDER_HISTORY_1_STAGE_TEMPLATE } from 'manager/template-constant';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * 発注実績一覧サービス
 */
export class OrderHistory1StageService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 発注実績ステータス一覧を取得
   */
  searchStatusList(): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_ORDER,
      '?TemplateID=',
      ORDER_HISTORY_1_STAGE_TEMPLATE.SEARCH_RESULTS_STATUS_TEMPLATE_ID,
      '&CodeConvFLAG=0'
    );

    // 発注実績ステータス一覧を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
