<!-- メイン領域-->
<div class="main-container">
  <!-- 一覧画面表示領域-->
  <p-table
    [value]="searchResultsList"
    [paginator]="false"
    [rows]="10"
    [showCurrentPageReport]="false"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]"
    styleClass="p-datatable-striped output-datatable"
    selectionMode="multiple"
    [(selection)]="idSelected"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-end">
        <!-- 連携データcsv出力-->
        <button
          type="button"
          pButton
          icon="pi pi-file-o"
          (click)="exportTSV()"
          class="p-mr-2 button"
          pTooltip="情報出力"
          tooltipPosition="bottom"
        ></button>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- 選択チェックボックスヘッダーの表示 -->
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOutput">
          <th>
            {{ column.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr [pSelectableRow]="searchResults">
        <!-- チェックボックス -->
        <td>
          <p-tableCheckbox [value]="searchResults"></p-tableCheckbox>
        </td>
        <!-- 一覧項目値表示 -->
        <ng-container *ngFor="let column of columnOutput">
          <td [ngSwitch]="column?.input_type">
            <span *ngSwitchCase="'NUMBER'">
              {{ searchResults[column.field] | number }}
            </span>
            <span *ngSwitchDefault>
              {{ searchResults[column.field] }}
            </span>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>

<!-- 出力確認ダイアログ -->
<p-confirmDialog #cd icon="pi pi-exclamation-triangle">
  <p-footer>
    <div id="OutputConfirmDialog" class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- CSV出力 -->
<app-export-file></app-export-file>
