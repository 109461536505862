/** 発注実績画面 */
// 発注実績画面用
export const ORDER_PERFORMANCE_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'order',
  // 詳細副題名
  DETAIL_SUB_TITLE: '発注者情報',
  // 注文ID
  ORDER_ID: 'order_id',
  // 注文ステータス
  STATUS: 'status',
  // 承認可否1
  APPROVAL1: 'approval1',
  // 承認可否2
  APPROVAL2: 'approval2',
  // 対象テーブル
  TARGET_TABLE: 'order_user_department',
};

// ステータス更新画面のタイトル
export const STATUS_UPDATE_TITLE = {
  // 配送依頼(ステータス)
  DELIVERY_REQUEST: '注文承認可否(部門)',

  // 承認待ち(ステータス)
  APPROVAL_PENDING: '注文承認可否(上長)',
};
