import { TOAST } from '../../constant/primeng-constants';

/** TOASTメッセージデフォルト定数 */
export const TOAST_MESSAGE_DEFAULT_CONSTANT = {
  // 重大度
  severity: TOAST.SUCCESS,
  // 表示時間
  life: 3000,
  // TOASTメッセージ出力場所
  position: TOAST.TOP_CENTER,
};
