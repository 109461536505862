import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  API_URL_INPUT_WORK_ORDER,
  API_URL_WORK_ORDER,
  API_URL_ORDER,
} from 'manager/http-constants_key';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { WORK_ORDER_TEMPLATE } from 'manager/template-constant';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QuantityService } from './quantity.service';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-order',
  templateUrl: './quantity.component.html',
  styleUrls: ['../order.component.scss', './quantity.component.scss'],
})
export class QuantityComponent implements OnInit {
  first = 0;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // 検索エンドポイント
  public endPointSearch: string = API_URL_INPUT_WORK_ORDER;

  // 画面の入力項目一覧
  inputItemsList: any[];

  /** 編集中 */
  editFlg = true;
  // 登録ボタンラベル
  registLabel: string;
  // リセットボタンラベル
  resetLabel: string;

  column_id_pKey: string;
  pKeyList: string[] = [];

  deleteDialogMessageDataColumnIdList = ['item_code', 'delivery_id'];

  // フォーム用
  // formGroup: FormGroup;
  formArray: FormArray;

  dispErrors: true;

  errorMessages: {
    index: number;
    columnId: string;
    columnName: string;
    detail: string;
  }[] = [];

  delivery_type_list: {
    index: string;
    list: string;
  }[] = [];

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  continuousPress: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private formBuilder: FormBuilder,
    private quantityService: QuantityService,
    private messageService: MessageService,
    private router: Router,
    private commonService: CommonService,
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {
    // PrimeNGを日本語化
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  ngOnInit() {
    // ラベル設定
    this.registLabel = '確認';
    this.resetLabel = 'リセット';
    // 登録ボタン押下フラグを初期化
    this.continuousPress = false;
    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  searchResult(generateSearchItems?: Object) {
    // 登録フォーム入力値初期化処理
    this.formArray = this.formBuilder.array([]);
    // this.formGroup = this.formBuilder.group({
    //   list: this.formArray
    // });
    this.pKeyList = [];
    this.first = 0;
    this.errorMessages = [];
    this.dispErrors = true;
    this.delivery_type_list = [];

    // 入力項目取得
    this.dbOperationService
      .getData(API_URL_INPUT_WORK_ORDER, WORK_ORDER_TEMPLATE.INPUT_TEMPLATE_ID)
      .subscribe((inputData) => {
        this.inputItemsList = inputData.body;
        // 一覧取得
        this.dbOperationService
          .getData(
            API_URL_WORK_ORDER,
            WORK_ORDER_TEMPLATE.INPUT_TEMPLATE_ID,
            generateSearchItems
          )
          .subscribe((listData) => {
            listData.body.forEach((rowData) => {
              const rowForm = this.formBuilder.group({});
              let tmpid = rowData['id'];
              let tmplist = rowData['hope_delivery_type_keyval'];
              this.delivery_type_list.push({ index: tmpid, list: tmplist });

              Object.values(this.inputItemsList).forEach((inputItem) => {
                const columnId = inputItem['column_id'];
                if (!columnId) {
                  return;
                }
                let data = rowData[columnId];
                // 日付データはAPIからyyyy-MM-ddでくるので変換
                if (inputItem['input_type'] === 'date') {
                  if (data && typeof data === 'string') {
                    data = data.split('-').join('/');
                  }
                }

                if (inputItem['column_pkey'] === 'P') {
                  this.column_id_pKey = columnId;
                  this.pKeyList.push(data);
                }

                rowForm.addControl(columnId, new FormControl(data, []));
              });
              this.formArray.push(rowForm);
            });
          });
      });
  }

  /**
   * 再検索処理（仮）
   */
  searchReplacement() {
    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 削除処理
   * @param rowControl クリックされた行のformControl
   */
  delete(rowControl: FormGroup) {
    const pKey = rowControl.get(this.column_id_pKey).value;

    const dispCodeArray = this.deleteDialogMessageDataColumnIdList.map(
      (colId) => {
        const codeValue = rowControl.get(colId).value + '';
        const codeName =
          this.inputItemsList.find((one) => one.column_id === colId)
            ?.column_name + '';
        return { codeName, codeValue };
      }
    );

    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteByPkeyDialog(
      API_URL_WORK_ORDER,
      dispCodeArray,
      pKey
    );
  }

  /**
   * 行削除成功時の処理
   */
  onDeleteSucces(pKey: string) {
    // 全体再描画は行わず、対象を削除する。
    const index = this.pKeyList.findIndex((one) => one === pKey);
    this.formArray.removeAt(index);
    this.pKeyList.splice(index, 1);

    // エラーリストも再構築
    this.errorMessages = this.errorMessages
      .filter((one) => one.index !== index)
      .map((one) => {
        if (one.index > index) {
          one.index -= 1;
        }
        return one;
      });
  }

  /**
   * ソート処理。仮状態なのでプルダウン系のソートはできていない。
   *
   */
  // customSort(event: SortEvent) {
  //   event.data.sort((data1, data2) => {

  //       let value1 = data1?.controls[event.field]?.value;
  //       let value2 = data2?.controls[event.field]?.value;
  //       let result = null;

  //       if (value1 == null && value2 != null)
  //           result = -1;
  //       else if (value1 != null && value2 == null)
  //           result = 1;
  //       else if (value1 == null && value2 == null)
  //           result = 0;
  //       else if (typeof value1 === 'string' && typeof value2 === 'string')
  //           result = value1.localeCompare(value2);
  //       else
  //           result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

  //       return (event.order * result);
  //   });
  // }

  onClickReset() {
    if (this.editFlg) {
      this.searchResult(['resetMode=1']);
    } else {
      this.editFlg = true;
      // ラベル設定
      this.registLabel = '確認';
      this.resetLabel = 'リセット';
    }
  }
  onClickRegist() {
    this.errorMessages = [];
    this.dispErrors = true;
    if (this.editFlg) {
      const dataArray = this.formArray.getRawValue();
      this.quantityService
        .addOrderCheck(dataArray, API_URL_ORDER)
        .subscribe((response) => {
          const data = response.body[0];
          if (API_RESPONSE.SUCCESS == data.result) {
            // 確認画面に変更
            this.editFlg = false;
            // ラベル設定
            this.registLabel = '登録';
            this.resetLabel = '戻る';
          } else {
            this.callBackOnAddOrderError(data.error);
          }
        });
    } else {
      this.callAddOrder();
    }
  }
  callAddOrder() {
    // 確認画面の登録ボタンを非活性化
    this.continuousPress = true;
    const inputItems = this.formArray.getRawValue();
    this.quantityService
      .addOrder(inputItems, API_URL_ORDER)
      .subscribe((response) => {
        const data = response.body[0];
        if (API_RESPONSE.SUCCESS == data.result) {
          // 完了メッセージの表示
          this.messageService.add({
            key: 'order-comp',
            severity: 'success',
            summary: this.commonService.msg(MESSAGE_CODE.T00001),
            detail: this.commonService.msg(MESSAGE_CODE.I00001),
          });
          // 遷移
        } else {
          // 登録ボタン押下フラグを初期化
          this.continuousPress = false;
          this.callBackOnAddOrderError(data.error);
        }
      });
  }
  redirect() {
    this.router.navigate(['pages/order/orderHistory1']);
  }

  callBackOnAddOrderError(error: any) {
    // formControlにエラーを入れる作戦はページングでいったり来たりするとinvalid扱いにならないことから断念。
    this.errorMessages = error.map((e) => {
      if (e.row_no && e.column_id) {
        const index = e.row_no - 1;
        const columnId = e.column_id;
        const message = e.message;

        const inputItem = this.inputItemsList.find(
          (item) => item.column_id === columnId
        );
        const colName = inputItem.column_name;
        return { index, columnId, columnName: colName, detail: message };
      } else {
        return {
          index: null,
          columnId: null,
          columnName: null,
          detail: e.message,
        };
      }
    });
  }

  getIndexFromFormGroup(fg: FormGroup): number {
    const pKey = fg.get(this.column_id_pKey).value;
    return this.pKeyList.findIndex((one) => one === pKey);
  }

  isInvalid(fg: FormGroup, column_id: string) {
    const index = this.getIndexFromFormGroup(fg);
    return this.errorMessages.some(
      (one) => one.index === index && one.columnId === column_id
    );
  }

  /**
   * 選択可能配送方法取得処理
   * @param rowControl クリックされた行のformControl
   */
  getlist(rowControl: FormGroup) {
    const pKey = rowControl.get(this.column_id_pKey).value;

    const tmp = this.delivery_type_list
      .filter((one) => one.index == pKey)
      .map((one) => {
        return one.list;
      });

    const list = tmp[0];
    return list;
  }
  /**
   * 選択可能配送方法名取得処理
   * @param rowControl クリックされた行のformControl
   */
  getName(rowControl: FormGroup): string {
    const pKey = rowControl.get(this.column_id_pKey).value;
    const typeValue = rowControl.get('hope_delivery_type').value;

    // 対象品番の配送方法配列を取得
    const tmp = this.delivery_type_list
      .filter((one) => one.index == pKey)
      .map((one) => {
        return one.list;
      });

    const list: any = tmp[0];
    // 選択した配送方法を取得
    const select = list.filter((delivery_type) => {
      return delivery_type.value == typeValue;
    });
    // console.log(select);
    // console.log(select[0].name);
    return select[0].name;
  }
}
