import { Injectable } from '@angular/core';
import { PROD_DOMAIN } from 'manager/environment';
import * as devURL from 'manager/http-constants_dev';
import * as prodURL from 'manager/http-constants_prod';
import {
  MESSAGE_CODE,
  MESSAGE,
} from 'src/app/shared/constant/message-constant';
import {
  CHARACTER_CODE,
  CONSTANT,
  FILE_CONSTANT,
  FILE_EXTENSION,
} from 'src/app/shared/constant/constant';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';

@Injectable({
  providedIn: 'root',
})

/**
 * 共通機能
 */
export class CommonService {
  constructor() {}

  /**
   * response判定(検索系の判定)
   * @param response レスポンス情報
   * @returns true レスポンスが存在しない場合
   * @returns false レスポンスが存在する場合
   */
  public checkNoneResponse(response: any): boolean {
    // responseが存在するか否か
    if (0 == response.body.length) {
      // responseが存在しない場合

      return true;
    }

    // responseのレコードが存在するか否か
    if (API_RESPONSE.NO_RECORD == response.body[0].Message) {
      // responseレコードが存在しない場合

      return true;
    }

    // responseが存在する場合
    return false;
  }

  /**
   * response判定(登録、更新、削除系の判定)
   * @param response レスポンス情報
   * @returns true レスポンス正常
   * @returns false レスポンス異常
   */
  public checkRunningNormallyResponse(response: any): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果が存在するか否か
    if (0 == response.body.length) {
      // レスポンス結果が存在しない場合

      // 異常終了
      return false;
    }

    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      // 異常終了
      return false;
    }

    // 正常終了
    return true;
  }

  /**
   * API URL生成
   * @param endPointKey REST APIエンドポイントキー
   * @param replaceArray 置き換える文字列
   * @returns URL
   */
  public url(endPointKey: string, ...replaceArray: any[]): string {
    // エンドポイント取得
    let endPoint =
      PROD_DOMAIN == window.location.host
        ? prodURL[endPointKey]
        : devURL[endPointKey];

    // エンドポイントが取得されたか否か
    if (!endPoint) {
      // エンドポイントが取得されなかった場合

      console.error(
        this.msg(
          MESSAGE_CODE.E80000,
          PROD_DOMAIN == window.location.host ? '本番用' : '開発用'
        )
      );

      return '';
    }

    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      // REST APIエンドポイントに置き換え文字列が存在するか否か
      if (endPoint.includes('{' + index + '}')) {
        // 置き換え文字列が存在する場合

        // 文字を置き換える
        endPoint = endPoint.replace('{' + index + '}', replace);
      } else {
        // 置き換え文字列が存在しない場合

        // 文字列の最後に連結する
        endPoint = endPoint.concat(replace);
      }
    });

    // URLを返却
    return endPoint;
  }

  /**
   * メッセージ内容取得
   * @param msgkey メッセージキー
   * @param replaceArray 置き換える文字列
   * @returns メッセージ内容
   */
  public msg(msgkey: string, ...replaceArray: any[]): string {
    // メッセージ情報取得
    let message = MESSAGE[msgkey];

    // メッセージ情報が存在するか否か
    if (!message) {
      // メッセージ情報が存在しない場合

      return '';
    }

    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      message = message.replace('{' + index + '}', replace);
    });

    return message;
  }

  /**
   * ファイル情報を取得
   * @param file ファイル情報
   */
  public getFile(file): any {
    return new Promise((resolve) => {
      // FileReader オブジェクトを生成
      const reader = new FileReader();
      // ファイルの中身を読み取る
      reader.readAsText(file, CHARACTER_CODE.SJIS);
      // ファイル情報の読み込みが正常終了の場合、ファイル情報を返却
      reader.onload = (event) => resolve(event.target.result);
      // ファイル情報の読み込みが異常終了の場合、コンソールにエラー出力
      reader.onerror = (error) => console.error(error);
    });
  }

  /**
   * ファイル情報をbase64化
   * @param file ファイル情報
   */
  public getBase64(file): any {
    return new Promise((resolve) => {
      // FileReader オブジェクトを生成
      const reader = new FileReader();
      // ファイルをbase64URL化しFileReaderに格納
      reader.readAsDataURL(file);
      // ファイル情報の読み込みが正常終了の場合、Base64化したファイル情報を返却
      reader.onload = (event) => resolve(event.target.result);
      // ファイル情報の読み込みが異常終了の場合、コンソールにエラー出力
      reader.onerror = (error) => console.error(error);
    });
  }

  /**
   * CSV or TSVファイル情報から配列を生成
   * @param fileInformation ファイル情報
   * @param extension 拡張子 .csv or .tsvのみ設定可能
   * @returns オブジェクト配列
   *
   * 返却レスポンスサンプル
   * "information_id,title"\r\n"1007","配送情報"\r\n"1207","【重要】臨時メンテナンスの実施について"
   * ↓
   * [
   *   {
   *     information_id: "1007",
   *     title: "配送情報"
   *   },
   *   {
   *     information_id: "1207",
   *     title: "【重要】臨時メンテナンスの実施について"
   *   }
   * ]
   */
  public createCsvDataObject(
    fileInformation: string,
    extension: string
  ): object[] {
    // ファイル情報の必須判定
    if (!fileInformation) {
      // ファイル情報が存在しない場合

      return null;
    }

    // ファイル拡張子の判定
    if (!(FILE_EXTENSION.CSV == extension || FILE_EXTENSION.TSV == extension)) {
      // ファイル拡張子が".csv"又は".tsv"以外の場合

      return null;
    }

    // 改行コードで配列化
    const informationList: string[] = fileInformation.split(
      FILE_CONSTANT.LINE_CODE
    );

    // ヘッダー部を格納先を宣言
    let header: string[];

    // 拡張子がcsv又はtsvか否か
    if (FILE_EXTENSION.CSV == extension) {
      // 拡張子がcsvの場合

      // カンマ区切りでヘッダーを格納
      header = informationList[0].split(CONSTANT.COMMA);
    } else if (FILE_EXTENSION.TSV == extension) {
      // 拡張子がtsvの場合

      // タブ区切りでヘッダーを格納
      header = informationList[0].split(FILE_CONSTANT.TAB);
    }

    // データ部(先頭行を削除)
    informationList.shift();

    // レスポンス配列を宣言
    let dataArrayObject: object[] = new Array();

    // データ部をループ
    for (const lineData of informationList) {
      // 行データが存在するか否か
      if (!lineData) {
        // 行データが存在しない場合

        continue;
      }

      // データ格納先を宣言
      let data: any[];

      // 拡張子がcsv又はtsvか否か
      if (FILE_EXTENSION.CSV == extension) {
        // 拡張子がcsvの場合

        // カンマ区切りでデータ部のデータを格納
        data = lineData.split(CONSTANT.COMMA);
      } else if (FILE_EXTENSION.TSV == extension) {
        // 拡張子がtsvの場合

        // タブ区切りでデータ部のデータを格納
        data = lineData.split(FILE_CONSTANT.TAB);
      }

      // 格納用オブジェクトを生成
      let objectData = new Object();

      // データをループ
      data.forEach((data, index) => {
        // ヘッダーでプロパティをを生成し、データを格納
        objectData[header[index]] = data;
      });

      // 格納用オブジェクトをレスポンス配列に格納
      dataArrayObject.push(objectData);
    }

    return dataArrayObject;
  }

  /**
   * ArrayObjectから該当キーで配列を生成
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @returns 該当データ配列
   *
   * 例 検索キー:header
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ["ユーザID", "ユーザ名称"]
   */
  public createArrayGetArrayObject(
    ArrayObject: Object[],
    searchKey: string
  ): string[] {
    // 検索対象ArrayObjectと検索キーの必須判定
    if (!ArrayObject || !searchKey) {
      // 検索対象ArrayObjectか検索キーが存在しない場合

      return null;
    }

    // ArrayObjectから対象のsearchKeyでArrayを作成
    return ArrayObject.map((val) => {
      return [val[searchKey]].join(CONSTANT.COMMA);
    });
  }

  /**
   * ArrayObjectから該当データを取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値
   * @returns 該当データ
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ユーザ名称
   */
  public getArrayObjectValue(
    ArrayObject: Object[],
    searchKey: string,
    getKey: string,
    searchValue: string | number
  ): any {
    // ArrayObjectから対象のobjectを取得
    const object = ArrayObject.filter((val) => val[searchKey] == searchValue);

    // objectが取得できたか否か
    if (0 == object.length) {
      return null;
    }

    // objectから対象データを取得
    return object[0][getKey];
  }

  /**
   * ArrayObjectから該当データを複数取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値
   * @returns 該当データ配列
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name", header: "ユーザカナ"}]
   * → ["ユーザ名称", "ユーザカナ"]
   */
  public getArrayObjectMultipleValue(
    ArrayObject: object[],
    searchKey: string,
    getKey: string,
    searchValue: []
  ): string[] {
    // 取得データを格納
    let array = new Array();

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 検索値をループ
      searchValue.forEach((data) => {
        // 検索対象ArrayObjectの検索キーと検索値を判定
        if (object[searchKey] == data) {
          // 検索対象ArrayObjectの検索キーと検索値が一致した場合

          // 検索対象ArrayObjectの取得キーを配列に格納
          array.push(object[getKey]);
        }
      });
    }

    // 取得データが存在するか確認
    if (0 == array.length) {
      // データが存在しない場合
      return null;
    }

    // 取得データを返却
    return array;
  }
}
