import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})

/**
 * お知らせカテゴリ画面
 */
export class NoticeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
