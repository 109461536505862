<!-- 発注管理リスト -->
<app-breadcrumb></app-breadcrumb>

<!-- 選択販促資材リスト -->
<div class="cart-item">
  <app-cart-item></app-cart-item>
</div>

<!-- 選択届先リスト -->
<div class="cart-delivery">
  <app-cart-delivery></app-cart-delivery>
</div>
