import { Component, Input, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { NoticeListService } from './notice-list.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-notice-list',
  templateUrl: './notice-list.component.html',
  styleUrls: ['./notice-list.component.scss'],
})

/**
 * お知らせ一覧画面
 */
export class NoticeListComponent implements OnInit {
  // インプット.カテゴリID(初期値:ブランク)
  @Input() informationType: string = CONSTANT.EMPTY_STRING;

  // お知らせ確認格納先
  noticeList: any[] = new Array();

  // 更新時間ソート
  // 更新時間Key
  sortKey: string;
  sortOrder: number;
  sortField: string;

  // 更新時間value
  sortOptions: SelectItem[] = [
    { label: '降順', value: '!disp_valid_from' },
    { label: '昇順', value: 'disp_valid_from' },
  ];

  // お知らせ詳細表示フラグ
  detailModal: boolean;

  // お知らせ詳細情報
  noticeDetail: any;

  constructor(
    private noticeListService: NoticeListService,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    this.searchNoticeList();
  }

  /**
   * 検索処理
   */
  protected searchNoticeList() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.TOP_NOTICE + this.informationType);

    // お知らせ確認取得処理
    this.noticeListService
      .getData(this.informationType)
      .subscribe((response) => {
        // コード値のお知らせ確認情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          //  お知らせ確認情報のJSONをオブジェクトに格納する
          this.noticeList = response.body;
        } else {
          this.noticeList = [];
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(
          0.3,
          LOADING_KEY.TOP_NOTICE + this.informationType
        );
      });
  }

  /**
   * ソート
   */
  protected onSortChange(event) {
    if (event.value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = event.value.substring(1, event.value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = event.value;
    }
  }

  /**
   * 詳細画面表示
   * @param notice 選択されたお知らせ情報
   */
  protected detail(notice: any) {
    // 詳細画面表示
    this.detailModal = true;

    //  選択されたお知らせ情報をお知らせ詳細として格納する
    this.noticeDetail = notice;
  }
}
