<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <p-table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100]"
    [(first)]="pages"
    styleClass="p-datatable-striped order-datatable"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
        </div>
        <div>
          <!-- 全件csv出力-->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            [disabled]="csvLoading"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th pSortableColumn="{{ column.field }}">
            {{ column.header }}
            <p-sortIcon field="{{ column.field }}"></p-sortIcon>
          </th>
        </ng-container>

        <!-- 操作ヘッダーの表示 -->
        <th class="operation">
          <p>操作</p>
        </th>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[column.field])"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>

        <!-- 操作表示 -->
        <td>
          <!-- 更新ボタン -->
          <button
            pButton
            pRipple
            (click)="
              update(
                searchResults[columnOrder[0].field],
                searchResults.statusKey
              )
            "
            icon="pi pi-refresh"
            class="p-button-rounded p-button-info p-mr-2"
            pTooltip="更新"
            tooltipPosition="bottom"
            *ngIf="
              (loginUser.department_type == 1 &&
                loginUser.admit == 0 &&
                searchResults.statusKey == 1) ||
              (((loginUser.department_type == 1 && loginUser.admit == 1) ||
                loginUser.department_type == 9) &&
                (searchResults.statusKey == 1 || searchResults.statusKey == 5))
            "
          ></button>

          <!-- 編集ボタン -->
          <button
            pButton
            (click)="edit(searchResults[columnOrder[0].field])"
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            pTooltip="編集"
            tooltipPosition="bottom"
            *ngIf="
              (loginUser.department_type == 2 ||
                loginUser.department_type == 9) &&
              searchResults.statusKey == 1
            "
          ></button>
          <!-- キャンセルボタン -->
          <button
            pButton
            (click)="
              delete(
                searchResults[columnOrder[0].field],
                searchResults.statusKey
              )
            "
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            pTooltip="キャンセル"
            tooltipPosition="bottom"
            *ngIf="
              (loginUser.department_type == 2 ||
                loginUser.department_type == 9) &&
              searchResults.statusKey == 1
            "
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult($event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 更新画面 -->
<app-update-status-2-stage
  (reloadID)="searchReplacement($event)"
></app-update-status-2-stage>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input (reloadID)="searchReplacement($event)"></app-generate-input>

<!-- 削除ダイアログ-->
<app-delete-dialog (reloadID)="searchReplacement($event)"></app-delete-dialog>

<!-- CSV出力 -->
<app-export-file></app-export-file>
