import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import {
  DEPARTMENT_LEVEL,
  DEPARTMENT_TYPE,
} from 'src/app/shared/constant/db-constant';
import { TITLE } from 'manager/environment';
import { LoadingState } from '../loading/loading-state';
import { LOADING_KEY } from '../../constant/constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;

  // タイトルヘッダーを設定
  title: string = TITLE.TITLE_HEADER;

  // カートボタン表示フラグ
  cartDisplayFlag: boolean = false;

  // メニュー情報
  menuitems: MenuItem[] = new Array();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.HEADER_MENU);

    // ログインユーザ情報取得処理
    this.loginService.getLoginUser().subscribe((response) => {
      // TODO KADOKAWAでは不要
      /*
      // ユーザ情報の組織種別を判定
      if (
        2 == response.body[0].department_type ||
        9 == response.body[0].department_type
      ) {
        // 2:寄贈先 or 9:その他

        // カートボタンを表示
        this.cartDisplayFlag = true;
      }
      */

      // メニュー情報設定
      this.menu(response.body[0]);

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd(LOADING_KEY.HEADER_MENU);
    });
  }

  /**
   * メニュー情報設定
   * @param loginUser ログインユーザ
   */
  private menu(loginUser: any) {
    // 発注管理
    let order: object = new Object();
    order['label'] = '注文';
    order['items'] = new Array();

    // マスタ
    let master: object = new Object();
    master['label'] = '管理';
    master['items'] = new Array();

    // 事務局
    let secretariat: object = new Object();
    secretariat['label'] = '事務局';
    secretariat['items'] = new Array();

    // TODO:KADOKAWA案件で不要の為、コメントアウト
    // 食品選択
    /*
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他

        const item: object = {
          label: '食品選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/item-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
    }
    */

    // TODO:KADOKAWA案件で不要の為、コメントアウト
    // 届先選択
    /*
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他

        const item: object = {
          label: '届先選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/delivery-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
    }
    */

    // TODO:KADOKAWA案件で不要の為、コメントアウト
    // 選択リスト
    /*
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 2:寄贈先 or 9:その他

        const item: object = {
          label: '選択リスト',
          icon: 'pi pi-search',
          routerLink: 'pages/order/cart',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
    }
    */

    // 注文登録
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 2:発注担当 or 9:その他

      const item: object = {
        label: '注文登録',
        icon: 'pi pi-search',
        routerLink: 'pages/order/orderRegister',
        routerLinkActiveOptions: 'active',
      };
      order['items'].push(item);
    }

    // TODO:KADOKAWA案件で不要の為、コメントアウト
    // 注文履歴(1段階認証)
    /*
    {
      const item: object = {
        label: '注文履歴',
        icon: 'pi pi-list',
        routerLink: 'pages/order/orderHistory1',
        routerLinkActiveOptions: 'active',
      };
      order['items'].push(item);
    }
    */

    // 注文履歴(2段階認証)
    {
      const item: object = {
        label: '注文履歴',
        icon: 'pi pi-list',
        routerLink: 'pages/order/orderHistory2',
        routerLinkActiveOptions: 'active',
      };
      order['items'].push(item);
    }

    // 販売促進マスタ
    if (
      DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 1:寄贈元の場合 or 3:配送事務局 or 9:その他

      const item: object = {
        label: '資材',
        icon: 'pi pi-file',
        routerLink: 'pages/master/item',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 届先マスタ
    // TODO:KADOKAWA案件で不要の為、コメントアウト
    /*
    if (
      DEPARTMENT_TYPE.TYPE_DESTINATION == departmentType ||
      DEPARTMENT_TYPE.TYPE_OFFICE == departmentType ||
      DEPARTMENT_TYPE.TYPE_OTHER == departmentType
    ) {
      // 2:寄贈先 or 3:配送事務局 or 9:その他

      const item: object = {
        label: '届先',
        icon: 'pi pi-file',
        routerLink: 'pages/master/delivery',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }
    */

    // TODO:KADOKAWA案件で不要の為、コメントアウト
    // 営業日マスタ
    // if ('9' == departmentType) {
    //   // 9:その他

    //   const item: object = {
    //     label: '営業日マスタ',
    //     icon: 'pi pi-file',
    //     routerLink: 'pages/master/bizday',
    //     routerLinkActiveOptions: 'active',
    //   };
    //   master['items'].push(item);
    // }

    // 組織マスタ
    if (
      DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 1:寄贈元の場合 or 3:配送事務局 or 9:その他

      const item: object = {
        label: '組織',
        icon: 'pi pi-file',
        routerLink: 'pages/master/department',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // ユーザーマスタ
    if (
      DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
      DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    ) {
      // 1:寄贈元の場合 or 3:配送事務局 or 9:その他

      const item: object = {
        label: 'ユーザ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/user',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // お知らせマスタ
    if (DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
      // 9:その他

      const item: object = {
        label: 'お知らせ',
        icon: 'pi pi-file',
        routerLink: 'pages/master/information',
        routerLinkActiveOptions: 'active',
      };
      master['items'].push(item);
    }

    // 発送ステータスファイル登録
    if (
      DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
      (DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type &&
        DEPARTMENT_LEVEL.LEVEL_MANAGEMENT == loginUser.department_level)
    ) {
      // 3:事務局ユーザー or (9:その他 and 組織レベルが1:管理)

      const item: object = {
        label: '発送ステータス登録',
        icon: 'pi pi-upload',
        routerLink: 'pages/secretariat/registerShippingResult',
        routerLinkActiveOptions: 'active',
      };
      secretariat['items'].push(item);
    }

    // 事務局_情報出力
    if (
      DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
      (DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type &&
        DEPARTMENT_LEVEL.LEVEL_MANAGEMENT == loginUser.department_level)
    ) {
      // 3:事務局ユーザー or (9:その他 and 組織レベルが1:管理)

      const item: object = {
        label: '情報出力',
        icon: 'pi pi-file',
        routerLink: 'pages/secretariat/output',
        routerLinkActiveOptions: 'active',
      };
      secretariat['items'].push(item);
    }

    // 発注管理カテゴリのメニューが存在するか否か
    if (order['items'].length) {
      // 発注管理カテゴリのメニューが存在する場合

      // 発注管理カテゴリをメニューに追加
      this.menuitems.push(order);
    }

    // マスタカテゴリのメニューが存在するか否か
    if (master['items'].length) {
      // マスタカテゴリのメニューが存在する場合

      // マスタカテゴリをメニューに追加
      this.menuitems.push(master);
    }

    // 事務局カテゴリのメニューが存在するか否か
    if (secretariat['items'].length) {
      // 事務局カテゴリのメニューが存在する場合

      // 事務局カテゴリをメニューに追加
      this.menuitems.push(secretariat);
    }

    // ログアウト
    {
      const logout: object = {
        items: [
          {
            label: 'ログアウト',
            icon: 'pi pi-times-circle',
            command: (event) => {
              this.loginService.logout();
            },
          },
        ],
      };
      this.menuitems.push(logout);
    }
  }

  /**
   * Top画面遷移
   */
  onHome() {
    this.router.navigate(['pages/top']);
  }

  /**
   * カート画面遷移
   */
  onCart() {
    this.router.navigate(['pages/order/cart']);
  }
}
