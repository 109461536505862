/**
 * ルート権限設定
 */
/**
 * 注意
 * 画面を追加する場合は"Component"名で定数追加
 * 内部はpath,admit,department_level,department_typeを設定
 * 個別権限の場合はindividual_authority_数値連番:{path,admit,department_level,department_type}で設定(値は必ずどれか1つを設定)
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * path,admit,department_level,department_typeの場合、設定値は以下の通り設定
 * 1.department_type:''(空)→チェックを行わない
 * 2.department_type:'9'(1つ)→設定値でチェック
 * 3.department_type:'1,9'(2つ以上)→該当設定値に一致するかチェック
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 * 個別権限(individual_authority_数値連番)の場合、設定値は以下の通り設定
 * 1.department_type:''(空)→チェックを行わない
 * 2.department_type:'9'(1つ)→設定値でチェック
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
 */
/** 共通 */
// TOP
export const TopComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/** マスタ */
// 営業日マスタ
export const BizdayComponent = {
  // 画面パス
  path: 'pages/master/bizday',
  // ユーザマスタ.権限レベル
  admit: '99',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 届先マスタ
export const DeliveryComponent = {
  // 画面パス
  path: 'pages/master/delivery',
  // ユーザマスタ.権限レベル
  admit: '99',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 組織マスタ
export const DepartmentComponent = {
  // 画面パス
  path: 'pages/master/department',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,3,9',
};

// お知らせマスタ
export const InformationComponent = {
  // 画面パス
  path: 'pages/master/information',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '9',
};

// 販促資材マスタ
export const ItemComponent = {
  // 画面パス
  path: 'pages/master/item',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3,9',
};

// ユーザーマスタ
export const UserComponent = {
  // 画面パス
  path: 'pages/master/user',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1,3,9',
};

/** 発注管理 */
// カート
export const CartComponent = {
  // 画面パス
  path: 'pages/order/cart',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 届先検索
export const DeliverySearchComponent = {
  // 画面パス
  path: 'pages/order/delivery-search',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 販促資材検索
export const ItemSearchComponent = {
  // 画面パス
  path: 'pages/order/item-search',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 発注登録
export const OrderRegisterComponent = {
  // 画面パス
  path: 'pages/order/orderRegister',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '2,9',
};

// 発注実績(1段階認証)
export const OrderHistory1StageComponent = {
  // 画面パス
  path: 'pages/order/orderHistory1',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '99',
  // 組織マスタ.組織種別
  department_type: '99',
};

// 発注実績(2段階認証)
export const OrderHistory2StageComponent = {
  // 画面パス
  path: 'pages/order/orderHistory2',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

// 発注
export const QuantityComponent = {
  // 画面パス
  path: 'pages/order/quantity',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '2,9',
};

/** 事務局 */
// 発送ステータスファイル登録
export const RegisterShippingResultComponent = {
  // 画面パス
  path: 'pages/secretariat/registerShippingResult',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/secretariat/registerShippingResult',
    // ユーザマスタ.権限レベル
    admit: '',
    // 組織マスタ.組織レベル
    department_level: '1',
    // 組織マスタ.組織種別
    department_type: '9',
  },
};

// 情報出力
export const OutputComponent = {
  // 画面パス
  path: 'pages/secretariat/output',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3',
  // 個別権限1
  individual_authority_1: {
    // 画面パス
    path: 'pages/secretariat/output',
    // ユーザマスタ.権限レベル
    admit: '',
    // 組織マスタ.組織レベル
    department_level: '1',
    // 組織マスタ.組織種別
    department_type: '9',
  },
};

// メッセージ画面
export const MessageComponent = {
  // 画面パス
  path: '',
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};
