<div class="top">NEWS</div>
<div class="center">
  <!-- お知らせカテゴリ -->
  <p-tabView>
    <!-- 全て-->
    <p-tabPanel header="全て">
      <app-notice-list [informationType]=""></app-notice-list>
    </p-tabPanel>
    <!-- お知らせ-->
    <p-tabPanel header="お知らせ">
      <app-notice-list [informationType]="1"></app-notice-list>
    </p-tabPanel>
    <!-- 配送情報-->
    <p-tabPanel header="配送情報">
      <app-notice-list [informationType]="2"></app-notice-list>
    </p-tabPanel>
    <!-- メンテナンス-->
    <p-tabPanel header="メンテナンス">
      <app-notice-list [informationType]="3"></app-notice-list>
    </p-tabPanel>
  </p-tabView>
</div>
