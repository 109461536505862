import { Component, ElementRef, OnInit } from '@angular/core';
import * as Encoding from 'encoding-japanese';
import {
  CONSTANT,
  FILE_EXTENSION,
  FILE_CONSTANT,
} from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
})

/**
 * ファイル出力
 */
export class ExportFileComponent implements OnInit {
  // 画面情報格納
  element: HTMLElement;

  constructor(private elementRef: ElementRef) {
    // 画面情報を取得
    this.element = this.elementRef.nativeElement;
  }

  ngOnInit(): void {}

  /**
   * CSVファイル出力
   * @param fileName ファイル名
   * @param header ヘッダー情報
   * @param data データ情報
   */
  public exportCsv(fileName: string, header: string[], data: object[]) {
    // ファイル名とヘッダー情報の必須判定
    if (!fileName || !header) {
      // ファイル名かヘッダー情報が空かnullの場合

      return;
    }

    // ヘッダー情報を生成
    let record = header.join(CONSTANT.COMMA) + FILE_CONSTANT.LINE_CODE;

    // データ情報を生成
    for (const dataObject of data) {
      record +=
        CONSTANT.DOUBLE_QUOTATION +
        Object.values(dataObject).join(
          CONSTANT.DOUBLE_QUOTATION + CONSTANT.COMMA + CONSTANT.DOUBLE_QUOTATION
        ) +
        CONSTANT.DOUBLE_QUOTATION +
        FILE_CONSTANT.LINE_CODE;
    }

    // ファイル情報を生成
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, record], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // TODO IE対応
    // ブラウザ情報を取得
    const userAgent = window.navigator.userAgent.toLowerCase();

    // ブラウザを判定
    if (userAgent.indexOf('trident') == -1) {
      // IE以外の場合

      // エクセルファイルを出力
      const link: HTMLAnchorElement = this.element.querySelector(
        '#csvExport'
      ) as HTMLAnchorElement;

      link.href = url;
      link.download = fileName + FILE_EXTENSION.CSV;
      link.click();
    } else {
      // IEの場合

      // 特殊処理でエクセルファイルを出力
      // IEでは"blob"出力に対応していない為、特殊処理を実施
      window.navigator.msSaveBlob(blob, fileName + FILE_EXTENSION.CSV);
    }
  }

  /**
   * TSVファイル出力
   * @param fileName ファイル名
   * @param header ヘッダー情報
   * @param data データ情報
   */
  public exportTsv(fileName: string, header: string[], data: object[]) {
    // ファイル名とヘッダー情報の必須判定
    if (!fileName || !header) {
      // ファイル名かヘッダー情報が空かnullの場合

      return;
    }

    // ヘッダー情報を生成
    let record = header.join(FILE_CONSTANT.TAB) + FILE_CONSTANT.LINE_CODE;

    // データ情報を生成
    for (const dataObject of data) {
      record +=
        Object.values(dataObject).join(FILE_CONSTANT.TAB) +
        FILE_CONSTANT.LINE_CODE;
    }
    // Unicodeコードポイントの配列を作成しSJIS化
    let tmp_arry = [];
    for (let idx = 0; idx < record.length; idx++) {
      tmp_arry.push(record.charCodeAt(idx));
    }
    const sjis_arry = Encoding.convert(tmp_arry, {
      to: 'SJIS',
      from: 'UNICODE',
    });
    const uint_arry = new Uint8Array(sjis_arry);

    // ファイル情報を生成
    const blob = new Blob([uint_arry], { type: 'text/tab-separated-values' });
    const url = window.URL.createObjectURL(blob);

    // TODO IE対応
    // ブラウザ情報を取得
    const userAgent = window.navigator.userAgent.toLowerCase();

    // ブラウザを判定
    if (userAgent.indexOf('trident') == -1) {
      // IE以外の場合

      // エクセルファイルを出力
      const link: HTMLAnchorElement = this.element.querySelector(
        '#csvExport'
      ) as HTMLAnchorElement;

      link.href = url;
      link.download = fileName + FILE_EXTENSION.TSV;
      link.click();
    } else {
      // IEの場合

      // 特殊処理でエクセルファイルを出力
      // IEでは"blob"出力に対応していない為、特殊処理を実施
      window.navigator.msSaveBlob(blob, fileName + FILE_EXTENSION.TSV);
    }
  }
}
