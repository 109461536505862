<!-- 入力エラーメッセージ出力領域 -->
<p-messages severity="error" *ngIf="csvErrorMessageList.length">
  <!-- 入力エラーメッセージが存在する場合、表示 -->
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
        <div *ngFor="let csvErrorMessage of csvErrorMessageList">
          <!-- 行数表示 -->
          {{ csvErrorMessage.row_no }}行目 &nbsp; ヘッダー :
          {{ csvErrorMessage.column_id }} &nbsp; 値 :
          {{ csvErrorMessage.value }} &nbsp; メッセージ :
          {{ csvErrorMessage.message }}
          <br />
        </div>
      </div>
    </div>
  </ng-template>
</p-messages>
