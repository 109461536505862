<div class="register-shipping-result">
  <app-csv-upload
    [buttonName]="'発送ステータスファイル登録'"
    [tableId]="'order'"
    [execType]="execType"
    [csvUploadHeaderTemplateId]="csvUploadHeaderTemplateId"
    [toleranceExtension]="toleranceExtension"
    [fileName]="'発送ステータスファイル'"
  ></app-csv-upload>
</div>
