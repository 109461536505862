import { Component, OnInit } from '@angular/core';
import { TITLE } from '../../../../../manager/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

/**
 * フッダー
 */
export class FooterComponent implements OnInit {
  // タイトルフッダーを設定
  title: string = TITLE.TITLE_FOOTER;

  constructor() {}

  ngOnInit(): void {}
}
