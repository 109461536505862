/**
 * 設定テンプレートID
 */
/** TOP画面 */
// 入力項目生成テンプレートID
export const TOP = {
  INFORMATION: 1408,
};

/** 販促資材マスタ画面 */
export const ITEM_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1104,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1105,
  // 在庫管理用表示項目生成テンプレートID
  STOCK_OUTPUT_TEMPLATE_ID: 1108,
};

/** 届先マスタ画面 */
export const DELIVERY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1202,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1204,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1205,
};

/** 組織マスタ画面 */
export const DEPARTMENT_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1202,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1204,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1205,
};

/** ユーザーマスタ画面 */
export const USER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1301,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1302,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1303,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1304,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1305,
};

/** お知らせマスタ画面 */
export const INFORMATION_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1401,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1402,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1403,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1404,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1405,
};

/** 営業日マスタ画面 */
export const BIZDAY_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 1601,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 1602,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 1603,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 1604,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 1605,
};

/** 注文登録画面 */
export const ORDER_REGISTER_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 104,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 105,
};

/** 発注販促資材画面 */
export const ITEM_SEARCH_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 101,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 102,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 103,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 104,
};

/** 発注届先画面 */
export const DELIVERIES_SEARCH_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 105,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 106,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 107,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 108,
};

/** 発注管理選択画面 */
export const WORK_ORDER_TEMPLATE = {
  // 販促資材一覧
  ITEM_TEMPLATE_ID: 109,
  // 届先一覧
  DELIVERIES_TEMPLATE_ID: 110,
  // 注文情報（WORK）
  INPUT_TEMPLATE_ID: 111,
};

/** 発注実績一覧画面(1段階認証) */
export const ORDER_HISTORY_1_STAGE_TEMPLATE = {
  // 検索項目生成テンプレートID
  SEARCH_TEMPLATE_ID: 201,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 202,
  // 表示項目生成テンプレートID
  OUTPUT_TEMPLATE_ID: 203,
  // CSV出力テンプレートID
  CSV_TEMPLATE_ID: 204,
  // 入力項目生成テンプレートID
  INPUT_TEMPLATE_ID: 205,
  // 入力項目:配送依頼(ステータス)テンプレートID
  INPUT_DELIVERY_REQUEST_TEMPLATE_ID: 206,
  // 入力項目:出荷作業中(ステータス)テンプレートID
  INPUT_DURING_SHIPPING_WORK_TEMPLATE_ID: 207,
  // 入力項目:出荷済み(ステータス)テンプレートID
  INPUT_SHIPPED_TEMPLATE_ID: 208,
  // 申請者情報テンプレートID
  OUTPUT_TEMPLATE_ID_USER: 209,
  // 詳細情報:配送依頼(ステータス)テンプレートID
  OUTPUT_DELIVERY_REQUEST_TEMPLATE_ID: 210,
  // 詳細情報:出荷作業中(ステータス)テンプレートID
  OUTPUT_DURING_SHIPPING_WORK_TEMPLATE_ID: 210,
  // 詳細情報:出荷済み(ステータス)テンプレートID
  OUTPUT_SHIPPED_TEMPLATE_ID: 210,
  // 注文情報ステータス一覧テンプレートID
  SEARCH_RESULTS_STATUS_TEMPLATE_ID: 211,
};

/** 発注実績一覧画面(2段階認証) */
export const ORDER_HISTORY_2_STAGE_TEMPLATE = {
  // 管理者・承認者検索項目生成テンプレートID
  ADMINISTRATOR_SEARCH_TEMPLATE_ID: 201,
  // 管理者・承認者一覧項目テンプレートID
  ADMINISTRATOR_SEARCH_RESULTS_TEMPLATE_ID: 202,
  // 管理者・承認者表示項目生成テンプレートID
  ADMINISTRATOR_OUTPUT_TEMPLATE_ID: 203,
  // 管理者・承認者CSV出力テンプレートID
  ADMINISTRATOR_CSV_TEMPLATE_ID: 204,
  // 管理者・承認者(部門)入力項目生成テンプレートID
  DEPARTMENT_REQUEST_TEMPLATE_ID: 205,
  // 管理者・承認者(上長)入力項目生成テンプレートID
  SUPERIOR_REQUEST_TEMPLATE_ID: 206,
  // 詳細情報:管理者・承認者(部門)テンプレートID
  OUTPUT_DEPARTMENT_REQUEST_TEMPLATE_ID: 207,
  // 詳細情報:管理者・承認者(上長)テンプレートID
  OUTPUT_SUPERIOR_REQUEST_TEMPLATE_ID: 208,
  // 依頼者検索項目生成テンプレートID
  REQUESTER_SEARCH_TEMPLATE_ID: 209,
  // 依頼者一覧項目テンプレートID
  REQUESTER_SEARCH_RESULTS_TEMPLATE_ID: 210,
  // 依頼者表示項目生成テンプレートID
  REQUESTER_OUTPUT_TEMPLATE_ID: 211,
  // 依頼者CSV出力テンプレートID
  REQUESTER_CSV_TEMPLATE_ID: 212,
  // 依頼者入力項目生成テンプレートID
  REQUESTER_INPUT_TEMPLATE_ID: 213,
  // 注文情報ステータス一覧テンプレートID
  SEARCH_RESULTS_STATUS_TEMPLATE_ID: 214,
  // 管理者入力項目生成テンプレートID
  ADMINISTRATOR_INPUT_TEMPLATE_ID: 215,
};

/** 発送ステータスファイル登録画面 */
export const REGISTER_SHIPPING_RESULT_TEMPLATE = {
  // 確認画面ヘッダーテンプレートID
  CONFIRMATION_HEADER_TEMPLATE_ID: 910,
};

/** 情報出力画面 */
export const SECRETARIAT_OUTPUT_TEMPLATE = {
  // 検索項目生成テンプレートID(未使用)
  SEARCH_TEMPLATE_ID: 901,
  // 一覧項目テンプレートID
  SEARCH_RESULTS_TEMPLATE_ID: 902,
  // 詳細表示項目生成テンプレートID(未使用)
  OUTPUT_TEMPLATE_ID: 903,
  // CSV/TSV出力(注文情報)テンプレートID
  CSV_ORDER_TEMPLATE_ID: 904,
  // CSV/TSV出力(マスタ情報)テンプレートID(未使用)
  CSV_MASTER_TEMPLATE_ID: 999,
};
