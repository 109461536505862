import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_INPUT_ORDER,
  API_URL_ORDER,
  API_URL_ORDER_STATUS,
} from 'manager/http-constants_key';
import { ORDER_PERFORMANCE_CONSTANT, STATUS_UPDATE_TITLE } from '../constant';
import { ORDER_HISTORY_2_STAGE_TEMPLATE } from 'manager/template-constant';
import { ORDER_STATUS } from 'src/app/shared/constant/db-constant';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputFormComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { LOADING_KEY } from 'src/app/shared/constant/constant';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';

@Component({
  selector: 'app-update-status-2-stage',
  templateUrl: './update-status-2-stage.component.html',
  styleUrls: ['./update-status-2-stage.component.scss'],
})

/**
 * ステータス更新画面(2段階認証)
 */
export class UpdateStatus2StageComponent implements OnInit {
  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 画面タイトル
  title: string;

  // IDコード
  pkeyId: string;

  // ステータス
  statusKey: string;

  // ステータス更新画面ダイアログ表示フラグ
  updateModal: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) {}

  ngOnInit() {}

  /**
   * 更新画面表示
   * @param pkeyId 対象データのID
   * @param statusKey ステータス
   */
  public initial(pkeyId, statusKey) {
    // 更新画面ダイアログを表示
    this.updateModal = true;

    // 対象データのIDをプロパティに格納
    this.pkeyId = pkeyId;

    // ステータスをプロパティに格納
    this.statusKey = statusKey;

    // 入力エラーメッセージをクリア
    this.generateInputErrorMessageComponent.clear();

    // インプット.発注ステータスの判定
    if (ORDER_STATUS.STATUS_ONE == statusKey) {
      // 配送依頼の場合
      // 配送依頼(ステータス)タイトルを設定
      this.title = STATUS_UPDATE_TITLE.DELIVERY_REQUEST;

      // 配送依頼(ステータス)テンプレートIDで詳細情報表示
      let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_ORDER;
      inputGenerateDisplay.templateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.OUTPUT_DEPARTMENT_REQUEST_TEMPLATE_ID;
      this.generateDisplayInformationComponent.initial(
        inputGenerateDisplay,
        pkeyId
      );

      // 入力フォーム表示
      this.generateInputFormComponent.initial(
        GENERATE_INPUT_TYPE.NEW,
        API_URL_INPUT_ORDER,
        null,
        ORDER_HISTORY_2_STAGE_TEMPLATE.DEPARTMENT_REQUEST_TEMPLATE_ID,
        null
      );
    } else if (ORDER_STATUS.STATUS_FIVE == statusKey) {
      // 承認待ちの場合
      // 承認待ち(ステータス)タイトルを設定
      this.title = STATUS_UPDATE_TITLE.APPROVAL_PENDING;

      // 承認待ち(ステータス)テンプレートIDで詳細情報表示
      let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_ORDER;
      inputGenerateDisplay.templateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.OUTPUT_SUPERIOR_REQUEST_TEMPLATE_ID;
      this.generateDisplayInformationComponent.initial(
        inputGenerateDisplay,
        pkeyId
      );

      // 入力フォーム表示
      this.generateInputFormComponent.initial(
        GENERATE_INPUT_TYPE.NEW,
        API_URL_INPUT_ORDER,
        null,
        ORDER_HISTORY_2_STAGE_TEMPLATE.SUPERIOR_REQUEST_TEMPLATE_ID,
        null
      );
    }
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }

  /**
   * 登録処理
   */
  public insertGenerateInputInformation(generateInputInformation: any): void {
    // インプット.発注ステータスの判定
    if (ORDER_STATUS.STATUS_ONE == this.statusKey) {
      // 配送依頼の場合

      // 承認可否1の判定
      if (
        ORDER_STATUS.STATUS_ONE ==
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.APPROVAL1]
      ) {
        // 寄贈可能の場合

        // 承認待ち(ステータス)を設定
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
          ORDER_STATUS.STATUS_FIVE;
      } else {
        // 寄贈不可の場合

        // キャンセル(ステータス)を設定
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
          ORDER_STATUS.STATUS_NINE;
      }
    } else if (ORDER_STATUS.STATUS_FIVE == this.statusKey) {
      // 承認待ちの場合

      // 承認可否2の判定
      if (
        ORDER_STATUS.STATUS_ONE ==
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.APPROVAL2]
      ) {
        // 寄贈可能の場合

        // 承認済み(ステータス)を設定
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
          ORDER_STATUS.STATUS_SIX;
      } else {
        // 寄贈不可の場合

        // キャンセル(ステータス)を設定
        generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
          ORDER_STATUS.STATUS_NINE;
      }
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_UPDATE
    );

    // DBアップデート
    this.dbOperationService
      .updateData(
        API_URL_ORDER_STATUS,
        this.pkeyId,
        generateInputInformation.value
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

        // 入力レスポンスメッセージを表示
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.T00001),
            this.commonService.msg(MESSAGE_CODE.U00002)
          )
        ) {
          // 正常終了の場合

          // 更新対象IDに選択対象IDをセット
          this.reloadID.emit(this.pkeyId);

          // 入力画面ダイアログを閉じる
          this.updateModal = false;
        } else {
          // 異常終了の場合

          // 入力フォーム状態初期化
          this.generateInputFormComponent.resetFlag();
        }
      });
  }
}
