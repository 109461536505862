import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { API_URL_OUTPUT } from 'manager/http-constants_key';
import { SECRETARIAT_OUTPUT_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import {
  CONSTANT,
  DATE_FORMAT,
  LOADING_KEY,
} from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import { HEADER_LIST_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { SECRETARIAT_OUTPUT_CONSTANT } from './constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss'],
})

/**
 * 管理_情報出力
 */
export class OutputComponent implements OnInit {
  /** CSV/TSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOutput: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 出力対象id選択状態
  idSelected: any[] = new Array();

  constructor(
    private confirmationService: ConfirmationService,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    public datePipe: DatePipe,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(SECRETARIAT_OUTPUT_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOutput = response.body;
      });

    // 出力対象一覧取得
    this.searchResult();
  }

  /**
   * 検索処理
   */
  private searchResult() {
    // 出力対象一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_OUTPUT,
        SECRETARIAT_OUTPUT_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 出力対象一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * TSV出力
   */
  protected exportTSV() {
    /* 出力対象必須チェック確認 */
    // 出力対象が選択されているか否か
    if (!this.idSelected.length) {
      // 出力対象が選択されていない場合

      // メッセージを出力
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.T00006),
          detail: this.commonService.msg(MESSAGE_CODE.T00007),
        })
      );
      return;
    }

    /* 確認ダイアログ表示 */
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00005),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 選択データから出力IDリストを生成
        const outputIdList = this.commonService.createArrayGetArrayObject(
          this.idSelected,
          this.columnOutput[0].field
        );

        /* 出力IDを元にファイル情報を出力 */
        for (const outputId of outputIdList) {
          // id の値に応じてどのデータを取得するか決定
          switch (outputId) {
            case SECRETARIAT_OUTPUT_CONSTANT.ID_ORDER:
              // ファイル出力を実施
              this.exportFile(
                SECRETARIAT_OUTPUT_CONSTANT.CSV_ORDER_FILENAME,
                SECRETARIAT_OUTPUT_TEMPLATE.CSV_ORDER_TEMPLATE_ID,
                outputId
              );
              break;
            case SECRETARIAT_OUTPUT_CONSTANT.ID_MASTER:
              // ファイル出力を実施
              this.exportFile(
                SECRETARIAT_OUTPUT_CONSTANT.CSV_MASTER_FILENAME,
                SECRETARIAT_OUTPUT_TEMPLATE.CSV_MASTER_TEMPLATE_ID,
                outputId
              );
              break;
            default:
              // ファイル出力を実施
              this.exportFile(
                SECRETARIAT_OUTPUT_CONSTANT.CSV_ORDER_FILENAME,
                SECRETARIAT_OUTPUT_TEMPLATE.CSV_ORDER_TEMPLATE_ID,
                outputId
              );
              break;
          }
        }

        // 出力対象id選択状態をクリア
        this.idSelected = [];
      },
    });
  }

  /**
   * ファイル出力
   * @param fileName 出力ファイル名
   * @param csvTemplateId 使用するテンプレートID
   * @param outputId 出力ID
   */
  private exportFile(
    fileName: string,
    csvTemplateId: number,
    outputId: string
  ): void {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.U00008),
      }),
      LOADING_KEY.FILE_DOWNLOAD + outputId
    );

    /* ヘッダー情報取得処理(CSV用)  */
    this.dbOperationService
      .getHeaderList(csvTemplateId)
      .subscribe((response) => {
        // ヘッダー情報を作成
        const header = this.commonService.createArrayGetArrayObject(
          response.body,
          HEADER_LIST_API_CONSTANT.FIELD
        );

        /* 出力情報取得処理(CSV/TSV用)  */
        this.dbOperationService
          .updateData(API_URL_OUTPUT, outputId, {
            TemplateID: csvTemplateId,
            CodeConvFLAG: 0,
          })
          .subscribe((response) => {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.FILE_DOWNLOAD + outputId);

            // 出力情報が存在するか否か
            if (this.commonService.checkNoneResponse(response)) {
              // 出力情報が存在しない場合

              // エラーメッセージ表示
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(MESSAGE_CODE.E00002),
                  detail: this.commonService.msg(MESSAGE_CODE.S00001),
                })
              );

              return;
            }

            // 出力情報にメッセージが存在するか否か
            if (response.body[0].Message) {
              // 出力情報にメッセージが存在する場合

              // エラーメッセージ表示
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(MESSAGE_CODE.E00002),
                  detail: response.body[0].Message,
                })
              );

              return;
            }

            // TSVファイル出力
            this.exportFileComponent.exportTsv(
              fileName +
                CONSTANT.UNDERBAR +
                this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME),
              header,
              response.body
            );

            // メッセージ表示
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.INFO,
                summary: this.commonService.msg(MESSAGE_CODE.T00001),
                detail: this.commonService.msg(MESSAGE_CODE.U00005),
              })
            );

            // 出力対象一覧取得
            this.searchResult();
          });
      });
  }
}
