/**
 * API URL(開発用)
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/uploadimage/';
// ファイル登録
export const API_URL_UPLOAD_CSV =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/uploadcsv/';
// ファイル情報登録
export const API_URL_REGIST_DATA =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/registdata/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/runcount/';
// 辞書値取得
export const API_URL_DICVALUES =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/dicvalues/';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT =
  'https://pumv0lq4q5.execute-api.ap-northeast-1.amazonaws.com/prod/output/';

/**
 * 画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/informations2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/informations/';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/searchinformations/';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/inputinformations/';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/users/';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/searchusers/';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/inputusers/';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/bizdays/';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/searchbizdays/';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/inputbizdays/';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/departments/';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/searchdepartments/';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT =
  'https://l5c2hjykm5.execute-api.ap-northeast-1.amazonaws.com/prod/inputdepartments/';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK =
  'https://rgocfn378c.execute-api.ap-northeast-1.amazonaws.com/prod/stock/';

// 届先マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/deliveries/';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/searchdeliveries/';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/inputdeliveries/';

// 販促資材マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/items/';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/searchitems/';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM =
  'https://7pnwj33o6d.execute-api.ap-northeast-1.amazonaws.com/prod/inputitems/';

// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/orders/';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/searchorders/';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/inputorders/';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/userdepartments/';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/workorders/';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/orders/{0}/status';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/orders/{0}/cancel/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/inputworkorders/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/inputorderitems/';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/orderdeliveries/';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/searchorderdeliveries/';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/workorderdeliveries/';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/workitemstockinfo/';
// 発注管理_販促資材／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_ITEM =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/orderitems/';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/searchorderitems/';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM =
  'https://hcuczn5q84.execute-api.ap-northeast-1.amazonaws.com/prod/workorderitems/';
