<!-- ローディング画面全域 -->
<div
  class="loading"
  [style.background-color]="loading.loadData.background_color"
  [style.opacity]="loading.loadData.opacity"
  *ngIf="loading.loadingFlag"
>
  <!-- ローディングアイコン -->
  <p class="loadingIcon">
    <p-progressSpinner
      strokeWidth="6"
      animationDuration=".5s"
    ></p-progressSpinner>
  </p>

  <!-- ローディングテキスト -->
  <p class="loadingText" [style.color]="loading.loadData.color">
    {{ loading.loadData.loadingText }}

    <!-- 変動テキスト(処理生成後) -->
    <label *ngIf="changeText; else noChangeText">
      {{ changeText }}
    </label>

    <!-- 変動テキスト(処理生成前) -->
    <!-- 画面に前の変動テキストが処理生成が完了するまで残る為、代替えの定数を行う -->
    <ng-template #noChangeText>
      {{ loading.loadData.changeText }}
    </ng-template>
  </p>
</div>
