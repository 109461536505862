<!-- Toastメッセージ表示 -->
<p-toast
  [key]="toastMessagePosition.TOP_RIGHT"
  [position]="toastMessagePosition.TOP_RIGHT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.TOP_LEFT"
  [position]="toastMessagePosition.TOP_LEFT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.BOTTOM_RIGHT"
  [position]="toastMessagePosition.BOTTOM_RIGHT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(100%)"
  hideTransformOptions="translateX(100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.BOTTOM_LEFT"
  [position]="toastMessagePosition.BOTTOM_LEFT"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateX(-100%)"
  hideTransformOptions="translateX(-100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.TOP_CENTER"
  [position]="toastMessagePosition.TOP_CENTER"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateY(-100%)"
  hideTransformOptions="translateY(-100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.BOTTOM_CENTER"
  [position]="toastMessagePosition.BOTTOM_CENTER"
  [preventOpenDuplicates]="true"
  showTransformOptions="translateY(100%)"
  hideTransformOptions="translateY(100%)"
></p-toast>
<p-toast
  [key]="toastMessagePosition.CENTER"
  [position]="toastMessagePosition.CENTER"
  [preventOpenDuplicates]="true"
></p-toast>
