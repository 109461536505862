import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** Auth0 */
import { AuthGuard } from '@auth0/auth0-angular';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { UserComponent } from './master/user/user.component';

// ORDER
import { CartComponent } from './order/cart/cart.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { OrderRegisterComponent } from './order/order-register/order-register.component';
import { OrderHistory1StageComponent } from './order/order-history-1-stage/order-history-1-stage.component';
import { OrderHistory2StageComponent } from './order/order-history-2-stage/order-history-2-stage.component';
import { QuantityComponent } from './order/quantity/quantity.component';

// SECRETARIAT
import { RegisterShippingResultComponent } from './secretariat/register-shipping-result/register-shipping-result.component';
import { OutputComponent } from './secretariat/output/output.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

const routes: Routes = [
  {
    // トップ画面
    path: '',
    component: TopComponent,
    canActivate: [AuthGuard],
  },
  {
    // トップ画面
    path: 'pages/top',
    component: TopComponent,
    canActivate: [AuthGuard],
  },
  {
    // 営業日マスタ画面
    path: 'pages/master/bizday',
    component: BizdayComponent,
    canActivate: [AuthGuard],
  },
  {
    // 届先マスタ画面
    path: 'pages/master/delivery',
    component: DeliveryComponent,
    canActivate: [AuthGuard],
  },
  {
    // 組織マスタ画面
    path: 'pages/master/department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
  },
  {
    // お知らせマスタ画面
    path: 'pages/master/information',
    component: InformationComponent,
    canActivate: [AuthGuard],
  },
  {
    // 販促資材マスタ画面
    path: 'pages/master/item',
    component: ItemComponent,
    canActivate: [AuthGuard],
  },
  {
    // ユーザーマスタ画面
    path: 'pages/master/user',
    component: UserComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 カート画面
    path: 'pages/order/cart',
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 届先検索画面
    path: 'pages/order/delivery-search',
    component: DeliverySearchComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 販促資材検索画面
    path: 'pages/order/item-search',
    component: ItemSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 注文登録画面
    path: 'pages/order/orderRegister',
    component: OrderRegisterComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 発注実績画面(1段階認証)
    path: 'pages/order/orderHistory1',
    component: OrderHistory1StageComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 発注実績画面(2段階認証)
    path: 'pages/order/orderHistory2',
    component: OrderHistory2StageComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注管理 発注画面
    path: 'pages/order/quantity',
    component: QuantityComponent,
    canActivate: [AuthGuard],
  },
  {
    // 事務局 発送ステータスファイル登録
    path: 'pages/secretariat/registerShippingResult',
    component: RegisterShippingResultComponent,
    canActivate: [AuthGuard],
  },
  {
    // 事務局_情報出力画面
    path: 'pages/secretariat/output',
    component: OutputComponent,
    canActivate: [AuthGuard],
  },
  {
    // メッセージ画面
    path: 'pages/message',
    component: MessageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
