<!-- 詳細情報出力オブジェクトリスト(画面表示情報)分ループ-->
<ng-container *ngFor="let outputGenerateDisplay of outputGenerateDisplayList">
  <!-- 詳細情報出力領域 -->
  <p-table
    [value]="outputGenerateDisplay.generateDisplay"
    styleClass="p-datatable-sm"
  >
    <!-- 詳細情報タイトル -->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <th colspan="2">{{ outputGenerateDisplay.title }}</th>
      </tr>
    </ng-template>
    <!-- 詳細情報出力箇所 -->
    <ng-template pTemplate="body" let-generateDisplayInformation>
      <tr>
        <!-- カラム名称 -->
        <td class="table-body-label">
          {{ generateDisplayInformation.column_name }}
        </td>

        <!-- カラムデータ -->
        <!-- データ判定 -->
        <!-- 画像タイプの場合 -->
        <td
          class="table-body-data"
          *ngIf="
            generateDisplayInformation.column_id == 'thumbnaill_image' ||
              generateDisplayInformation.column_id == 'thumbnails_image' ||
              generateDisplayInformation.column_id == 'original_image' ||
              generateDisplayInformation.column_id == 'item_image';
            else imageExcepting
          "
        >
          <!-- TODO 画像のカラム判定は暫定対応-->
          <img [src]="generateDisplayInformation.data" alt="image" />
        </td>

        <!-- 画像タイプ以外の場合 -->
        <ng-template #imageExcepting>
          <!-- 特定カラムIDの場合 -->
          <!-- TODO 特定カラムID判定は暫定対応-->
          <td
            class="table-body-data"
            *ngIf="
              generateDisplayInformation.column_id == 'item_code' ||
                generateDisplayInformation.column_id == 'delivery_id';
              else PkExcepting
            "
          >
            {{ generateDisplayInformation.data }}
          </td>

          <!-- 特定カラムID以外の場合 -->
          <ng-template #PkExcepting>
            <!-- 数字タイプの場合 -->
            <td
              class="table-body-data"
              *ngIf="
                generateDisplayInformation.input_type == 'NUMBER' &&
                generateDisplayInformation.column_pkey != 'P'
              "
            >
              {{ generateDisplayInformation.data | number }}
            </td>

            <!-- 文字タイプの場合 -->
            <td
              class="table-body-data"
              *ngIf="
                generateDisplayInformation.input_type == 'VARCHAR' ||
                generateDisplayInformation.input_type == 'DATE' ||
                (generateDisplayInformation.input_type == 'NUMBER' &&
                  generateDisplayInformation.column_pkey == 'P')
              "
            >
              {{ generateDisplayInformation.data }}
            </td>
          </ng-template>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
  <!-- 改行 -->
  <br />
</ng-container>
