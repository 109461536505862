import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { OrderHistory1StageService } from './order-history-1-stage.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import {
  API_URL_ORDER,
  API_URL_INPUT_ORDER,
  API_URL_SEARCH_ORDER,
  API_URL_USER_DEPARTMENT,
  API_URL_ORDER_CANCEL,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { ORDER_PERFORMANCE_CONSTANT } from './constant';
import { ORDER_STATUS } from 'src/app/shared/constant/db-constant';
import { ORDER_HISTORY_1_STAGE_TEMPLATE } from 'manager/template-constant';
import { UpdateStatus1StageComponent } from './update-status-1-stage/update-status-1-stage.component';
import { HEADER_LIST_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-order-history-1-stage',
  templateUrl: './order-history-1-stage.component.html',
  styleUrls: ['../order.component.scss'],
})

/**
 * 発注実績一覧画面(1段階認証)
 */
export class OrderHistory1StageComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 更新画面ダイアログ */
  @ViewChild(UpdateStatus1StageComponent)
  updateStatus1StageComponent: UpdateStatus1StageComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  /** CSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER;

  // 検索項目生成テンプレートID
  public searchTemplateId: number =
    ORDER_HISTORY_1_STAGE_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 注文ステータス格納先
  searchStatusList: any[] = new Array();

  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // ページ数
  pages: number = 0;

  /* csv用プロパティ */
  // ヘッダー情報格納先(CSV用)
  csvColumnOrder: any[] = new Array();

  // 検索結果一覧格納先(CSV用)
  csvList: any[] = new Array();

  // CSVダウンロード可能フラグ
  csvLoading: boolean;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private orderHistory1StageService: OrderHistory1StageService,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(ORDER_HISTORY_1_STAGE_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    /* ヘッダー情報取得処理(CSV用) */
    this.dbOperationService
      .getHeaderList(ORDER_HISTORY_1_STAGE_TEMPLATE.CSV_TEMPLATE_ID)
      .subscribe((response) => {
        this.csvColumnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 発注実績ステータス一覧取得処理 */
    this.orderHistory1StageService.searchStatusList().subscribe((response) => {
      // コード値の一覧情報が存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        this.searchStatusList = [];
        this.searchResultsList = [];
        this.csvList = [];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 処理を終了
        return;
      }

      // 発注実績一覧情報のJSONをオブジェクトに格納する
      this.searchStatusList = response.body;

      /* 発注実績一覧取得処理(画面用) */
      this.dbOperationService
        .getForkJoinData(
          ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
          API_URL_ORDER,
          ORDER_HISTORY_1_STAGE_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
          generateSearchItems
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 発注実績一覧情報のJSONをオブジェクトに格納する
            this.searchResultsList = this.setStatusKey(response.body);
          } else {
            this.searchResultsList = [];
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        });

      /* 発注実績一覧取得処理(CSV用) */
      this.dbOperationService
        .getForkJoinData(
          ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
          API_URL_ORDER,
          ORDER_HISTORY_1_STAGE_TEMPLATE.CSV_TEMPLATE_ID,
          generateSearchItems
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 発注実績一覧情報のJSONをオブジェクトに格納する
            this.csvList = response.body;
          } else {
            this.csvList = [];
          }

          // CSVダウンロードフラグをOFF(ロード終了)
          this.csvLoading = false;
        });
    });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    let inputGenerateDisplayList: InputGenerateDisplay[] = new Array();

    // 発注実績詳細
    {
      let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_ORDER;
      inputGenerateDisplay.templateId =
        ORDER_HISTORY_1_STAGE_TEMPLATE.OUTPUT_TEMPLATE_ID;
      inputGenerateDisplayList.push(inputGenerateDisplay);
    }

    // 発注者情報詳細
    {
      let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
      inputGenerateDisplay.title = ORDER_PERFORMANCE_CONSTANT.DETAIL_SUB_TITLE;
      inputGenerateDisplay.endPoint = API_URL_USER_DEPARTMENT;
      inputGenerateDisplay.templateId =
        ORDER_HISTORY_1_STAGE_TEMPLATE.OUTPUT_TEMPLATE_ID_USER;
      inputGenerateDisplayList.push(inputGenerateDisplay);
    }

    // 詳細画面表示
    this.generateDisplayItemsComponent.initial(
      inputGenerateDisplayList,
      pkeyId
    );
  }

  /**
   * 更新画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param statusKey 発注ステータス
   * @param deliveryTypeKey 決定配送方法
   */
  protected update(pkeyId: string, statusKey: number, deliveryTypeKey: number) {
    // ログインユーザと発注ステータスに不正があるかどうか
    // 無理やりボタンを押下された場合のチェック処理(disabledを無理やり取り除かれた場合のチェック)
    if (
      !(
        (this.loginUser.department_type == 1 && statusKey == 1) ||
        (this.loginUser.department_type == 1 && statusKey == 2) ||
        (this.loginUser.department_type == 3 &&
          statusKey == 2 &&
          deliveryTypeKey == 2) ||
        (this.loginUser.department_type == 2 && statusKey == 3) ||
        (this.loginUser.department_level == 1 &&
          this.loginUser.department_type == 9 &&
          (statusKey == 1 || statusKey == 2 || statusKey == 3))
      )
    ) {
      // 不正がある場合

      return;
    }

    // 更新画面表示
    this.updateStatus1StageComponent.initial(pkeyId, statusKey);
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param statusKey 発注ステータス
   */
  protected edit(pkeyId: string, statusKey: number) {
    // ログインユーザと発注ステータスに不正があるかどうか
    // 無理やりボタンを押下された場合のチェック処理(disabledを無理やり取り除かれた場合のチェック)
    if (
      !(
        (this.loginUser.department_type == 2 ||
          (this.loginUser.department_level == 1 &&
            this.loginUser.department_type == 9)) &&
        statusKey == 1
      )
    ) {
      // 不正がある場合

      return;
    }

    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_ORDER,
      API_URL_ORDER,
      ORDER_HISTORY_1_STAGE_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   * @param statusKey 発注ステータス
   */
  protected delete(pkeyId: string, statusKey: number) {
    // ログインユーザと発注ステータスに不正があるかどうか
    // 無理やりボタンを押下された場合のチェック処理(disabledを無理やり取り除かれた場合のチェック)
    if (
      !(
        (this.loginUser.department_type == 2 ||
          (this.loginUser.department_level == 1 &&
            this.loginUser.department_type == 9)) &&
        statusKey == 1
      )
    ) {
      // 不正がある場合

      return;
    }

    // 更新情報
    let updateValue = Object();

    // 発注ステータスにキャンセルをセット
    updateValue[ORDER_PERFORMANCE_CONSTANT.STATUS] = ORDER_STATUS.STATUS_NINE;

    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.cancelDialog(
      API_URL_ORDER_CANCEL,
      this.columnOrder[0].header,
      pkeyId,
      updateValue
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 発注実績ステータス一覧取得処理 */
    this.orderHistory1StageService.searchStatusList().subscribe((response) => {
      // コード値の一覧情報が存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        this.searchStatusList = [];
        this.searchResultsList = [];
        this.csvList = [];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 処理を終了
        return;
      }

      // 発注実績一覧情報のJSONをオブジェクトに格納する
      this.searchStatusList = response.body;

      /* 発注実績一覧取得処理(画面用) */
      this.dbOperationService
        .getSingleData(
          API_URL_ORDER,
          ORDER_HISTORY_1_STAGE_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
          this.columnOrder[0].field,
          pkeyId
        )
        .subscribe((response) => {
          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 発注実績一覧情報に 注文ステータスキーをセットする
          response.body = this.setStatusKey(response.body);

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();
        });

      /* 発注実績一覧取得処理(CSV用) */
      this.dbOperationService
        .getSingleData(
          API_URL_ORDER,
          ORDER_HISTORY_1_STAGE_TEMPLATE.CSV_TEMPLATE_ID,
          this.columnOrder[0].field,
          pkeyId
        )
        .subscribe((response) => {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先(CSV用)から対象のデータの行数を抽出
          const indexTargetData = this.csvList.indexOf(
            this.csvList.find((csvResults) => {
              return csvResults[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.csvList.splice(indexTargetData, 1, response.body[0]);

          // CSVダウンロードフラグをOFF(ロード終了)
          this.csvLoading = false;
        });
    });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ORDER_PERFORMANCE_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // ヘッダー情報を作成
    const header = this.commonService.createArrayGetArrayObject(
      this.csvColumnOrder,
      HEADER_LIST_API_CONSTANT.HEADER
    );

    // CSVファイル出力
    this.exportFileComponent.exportCsv(fileName, header, this.csvList);
  }

  /**
   * 注文ステータスキーのセット処理
   * @param data 注文ステータスキーをセットするオブジェクト
   */
  private setStatusKey(data: string[]): string[] {
    for (const dataValue of data) {
      // 注文ステータスキーをオブジェクトにセット
      dataValue['statusKey'] = this.commonService.getArrayObjectValue(
        this.searchStatusList,
        ORDER_PERFORMANCE_CONSTANT.ORDER_ID,
        ORDER_PERFORMANCE_CONSTANT.STATUS,
        dataValue[ORDER_PERFORMANCE_CONSTANT.ORDER_ID]
      );

      // 決定配送方法キーをオブジェクトにセット
      dataValue['deliveryTypeKey'] = this.commonService.getArrayObjectValue(
        this.searchStatusList,
        ORDER_PERFORMANCE_CONSTANT.ORDER_ID,
        ORDER_PERFORMANCE_CONSTANT.DELIVERY_TYPE,
        dataValue[ORDER_PERFORMANCE_CONSTANT.ORDER_ID]
      );
    }

    return data;
  }
}
