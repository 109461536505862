/** 入力エラーメッセージオブジェクト */
export class GenerateInputErrorMessage {
  // カラム名称
  private _columnName: string;

  // カラムエラーメッセージ
  private _columnErrorMessage: string;

  set columnName(columnName: string) {
    this._columnName = columnName;
  }

  get columnName(): string {
    return this._columnName;
  }

  set columnErrorMessage(columnErrorMessage: string) {
    this._columnErrorMessage = columnErrorMessage;
  }

  get columnErrorMessage(): string {
    return this._columnErrorMessage;
  }
}
