import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})
export class QuantityService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 注文情報登録
   *
   * @param inputItem 入力フォーム
   * @param endPoint REST APIエンドポイント
   * @returns JSONデータ
   */
  addOrder(inputItems: any[], endPoint: string): Observable<any> {
    const url = this.commonService.url(endPoint);
    const json = { list: inputItems, RegistFlag: 1 };
    return this.http.post(url, json, { observe: 'response' });
  }

  /**
   * 注文情報登録
   *
   * @param inputItem 入力フォーム
   * @param endPoint REST APIエンドポイント
   * @returns JSONデータ
   */
  addOrderCheck(inputItems: any[], endPoint: string): Observable<any> {
    const url = this.commonService.url(endPoint);
    const json = { list: inputItems, RegistFlag: 0 };
    return this.http.post(url, json, { observe: 'response' });
  }
}
