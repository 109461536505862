/** CSVファイル登録オブジェクト */
// 実行タイプ
export const EXEC_TYPE = {
  // 登録
  INSERT: 'I',
  // 更新(更新対象項目を更新)
  UPDATE: 'U',
  // 削除(削除日付を設定)
  DELETE: 'D',
  // 更新(データ全てを更新)
  // TODO 略語名称、動作想定が不明(KASOKAWAでは未使用の為、現状放置)
  UF: 'UF',
};

// デフォルトファイル名
export const DEFAULT_FILE_NAME = 'CSVファイルアップロード';
