<!-- メイン領域-->
<div class="main item">
  <p-dataView
    #dv
    [value]="noticeList"
    [paginator]="true"
    [rows]="6"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    filterBy="title"
    emptyMessage="お知らせは存在しません"
  >
    <ng-template pTemplate="header">
      <div class="p-d-flex">
        <div>
          <!-- タイトルフィルタ機能-->
          <span class="p-input-icon-left p-mb-2 p-mb-md-0">
            <i class="pi pi-search"></i>
            <input
              type="search"
              pInputText
              placeholder="タイトル"
              (input)="dv.filter($event.target.value)"
            />
          </span>
          <!-- ソート機能-->
          <p-dropdown
            [options]="sortOptions"
            [(ngModel)]="sortKey"
            placeholder="更新時間"
            (onChange)="onSortChange($event)"
          ></p-dropdown>
        </div>
      </div>
    </ng-template>

    <!-- リスト形式-->
    <ng-template let-notice pTemplate="listItem">
      <div class="p-col-12">
        <!-- お知らせ一覧アイテム-->
        <div class="notice-list-item" (click)="detail(notice)">
          <div class="notice-list-detail">
            <div class="notice-list-detail-top p-d-flex">
              <!-- お知らせ時間 -->
              <div class="notice-disp-valid-from">
                {{ notice.disp_valid_from }}
              </div>
              <!-- お知らせカテゴリ -->
              <div class="notice-information-type">
                <div class="information-type">
                  {{ notice.information_type }}
                </div>
              </div>
            </div>
            <!-- お知らせタイトル -->
            <div class="notice-list-detail-content">
              <div class="notice-title">{{ notice.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-dataView>

  <!-- お知らせ詳細ダイアログ -->
  <p-dialog
    header="{{ noticeDetail?.title }}"
    [(visible)]="detailModal"
    [modal]="true"
  >
    <div class="notice-list-detail-top p-d-flex">
      <!-- お知らせ時間 -->
      <div class="notice-disp-valid-from">
        {{ noticeDetail?.disp_valid_from }}
      </div>
      <!-- お知らせカテゴリ -->
      <div class="notice-information-type">
        <div class="information-type">
          {{ noticeDetail?.information_type }}
        </div>
      </div>
    </div>
    <!-- お知らせ詳細 -->
    <div class="notice-contents">
      {{ noticeDetail?.contents }}
    </div>
  </p-dialog>
</div>
