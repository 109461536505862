/**
 * API URL取得用キー
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS = 'USER_AUTHINFOS';
// ヘッダー項目生成
export const HEADER_LIST = 'HEADER_LIST';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT = 'API_URL_VALIDATION_RESULT';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT = 'API_URL_MULTIVALIDATION_RESULT';
// 画像登録
export const API_URL_UPLOAD_IMAGE = 'API_URL_UPLOAD_IMAGE';
// ファイル登録
export const API_URL_UPLOAD_CSV = 'API_URL_UPLOAD_CSV';
// ファイル情報登録
export const API_URL_REGIST_DATA = 'API_URL_REGIST_DATA';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT = 'API_URL_RUN_COUNT';
// 辞書値取得
export const API_URL_DICVALUES = 'API_URL_DICVALUES';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT = 'API_URL_OUTPUT';

/**
 * 画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 = 'API_URL_INFORMATIONS2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION = 'API_URL_INFORMATION';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION = 'API_URL_SEARCH_INFORMATION';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION = 'API_URL_INPUT_INFORMATION';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER = 'API_URL_USER';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER = 'API_URL_SEARCH_USER';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER = 'API_URL_INPUT_USER';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY = 'API_URL_BIZDAY';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY = 'API_URL_SEARCH_BIZDAY';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY = 'API_URL_INPUT_BIZDAY';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT = 'API_URL_DEPARTMENT';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT = 'API_URL_SEARCH_DEPARTMENT';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT = 'API_URL_INPUT_DEPARTMENT';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK = 'API_URL_STOCK';

// 届先マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY = 'API_URL_DELIVERY';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY = 'API_URL_SEARCH_DELIVERY';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY = 'API_URL_INPUT_DELIVERY';

// 販促資材マスタ／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM = 'API_URL_ITEM';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM = 'API_URL_SEARCH_ITEM';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM = 'API_URL_INPUT_ITEM';

// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER = 'API_URL_ORDER';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER = 'API_URL_SEARCH_ORDER';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER = 'API_URL_INPUT_ORDER';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT = 'API_URL_USER_DEPARTMENT';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER = 'API_URL_WORK_ORDER';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS = 'API_URL_ORDER_STATUS';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL = 'API_URL_ORDER_CANCEL';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER = 'API_URL_INPUT_WORK_ORDER';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM = 'API_URL_INPUT_ORDER_ITEM';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE = 'API_URL_ORDER_DELIVERIE';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE = 'API_URL_SEARCH_ORDER_DELIVERIE';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE = 'API_URL_WORK_ORDER_DELIVERIE';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'API_URL_WORK_ORDER_ITEM_STOCK_INFO';
// 発注管理_販促資材／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_ITEM = 'API_URL_ORDER_ITEM';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM = 'API_URL_SEARCH_ORDER_ITEM';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_ITEM = 'API_URL_WORK_ORDER_ITEM';
