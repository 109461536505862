<!-- 検索フォーム -->
<div class="GenerateSearch">
  <form [formGroup]="generateSearchForm">
    <!-- 検索情報出力領域 -->
    <p-table
      [value]="generateSearchList"
      styleClass="p-datatable-sm generate-search"
    >
      <ng-template pTemplate="body" let-generateSearch>
        <tr>
          <!-- カラム名称 -->
          <td class="label">
            <label>
              {{ generateSearch.column_name }}
            </label>
          </td>

          <!-- 検索項目タイプを判定 -->
          <td class="input-form">
            <!-- テキスト -->
            <div class="ui-fluid" *ngIf="generateSearch.search_type == 'text'">
              <input
                [name]="generateSearch.column_id"
                class="text"
                type="text"
                size="30"
                [formControlName]="generateSearch.column_id"
                pInputText
              />
            </div>

            <!-- 日付 -->
            <div
              class="ui-fluid input-start-end"
              *ngIf="generateSearch.search_type == 'date'"
            >
              <div class="p-col-fixed">
                <label> 開始&nbsp;: &nbsp;</label>
                <p-calendar
                  [name]="generateSearch.column_id + '_FROM'"
                  [showIcon]="true"
                  [showButtonBar]="true"
                  dateFormat="yy/mm/dd"
                  dataType="string"
                  appendTo="body"
                  [formControlName]="generateSearch.column_id + '_FROM'"
                  translate
                ></p-calendar>
              </div>
              <div class="verticalTilde">〜</div>
              <div class="p-col-fixed">
                <label> 終了&nbsp;: &nbsp;</label>
                <p-calendar
                  [name]="generateSearch.column_id + '_TO'"
                  [showIcon]="true"
                  [showButtonBar]="true"
                  dateFormat="yy/mm/dd"
                  dataType="string"
                  appendTo="body"
                  [formControlName]="generateSearch.column_id + '_TO'"
                  translate
                ></p-calendar>
              </div>
            </div>

            <!-- 数値 -->
            <div
              class="ui-fluid input-start-end"
              *ngIf="generateSearch.search_type == 'number'"
            >
              <div class="p-col-fixed">
                <label> 開始&nbsp;: &nbsp;</label>
                <input
                  [name]="generateSearch.column_id + '_FROM'"
                  type="number"
                  class="number"
                  [formControlName]="generateSearch.column_id + '_FROM'"
                  pInputText
                />
              </div>
              <div class="verticalTilde">〜</div>
              <div class="p-col-fixed">
                <label> 終了&nbsp;: &nbsp;</label>
                <input
                  [name]="generateSearch.column_id + '_TO'"
                  type="number"
                  class="number"
                  [formControlName]="generateSearch.column_id + '_TO'"
                  pInputText
                />
              </div>
            </div>

            <!-- チェックボックス -->
            <div
              class="ui-fluid"
              *ngIf="generateSearch.search_type == 'checkbox'"
            >
              <ng-container
                *ngFor="let code_list of generateSearch.code_list_multi"
              >
                <p class="p-field-checkbox">
                  <p-checkbox
                    [name]="generateSearch.column_id"
                    [label]="code_list.name"
                    [value]="code_list.value"
                    [formControl]="
                      generateSearchForm.controls[generateSearch.column_id]
                    "
                  ></p-checkbox>
                </p>
              </ng-container>
            </div>

            <!-- プルダウン -->
            <div
              class="ui-fluid"
              *ngIf="generateSearch.search_type == 'pulldown'"
            >
              <p-multiSelect
                [name]="generateSearch.column_id"
                [options]="generateSearch.code_list_multi"
                emptyFilterMessage="検索結果が存在しません"
                optionLabel="name"
                appendTo="body"
                [formControlName]="generateSearch.column_id"
              ></p-multiSelect>
            </div>

            <!-- テキストエリア -->
            <div class="ui-fluid" *ngIf="generateSearch.search_type == 'list'">
              <textarea
                [name]="generateSearch.column_id"
                maxlength="10000"
                [formControlName]="generateSearch.column_id"
                pInputTextarea
              ></textarea>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <input
      pButton
      type="submit"
      value="検索"
      class="p-button-raised p-mr-2 button"
      (click)="onSubmit()"
    />
    <input
      pButton
      type="submit"
      value="クリア"
      class="p-button-raised p-mr-2 button"
      (click)="reset()"
    />
  </div>
</div>
