/** ローディングデフォルト定数 */
export const LOADING_DEFAULT_CONSTANT = {
  // ローディングテキスト(不変)
  loadingText: 'Now Loading',
  // ローディングテキスト(変動)
  changeText: '...',
  // テキストカラー(白)
  color: '#ffffff',
  // 背景カラー(黒)
  background_color: '#000000',
  // 背景透明度(半透明)
  opacity: 0.5,
};

/** ローディングキー(なし) */
export const NONE_LOADING_KEY = 'none';
