<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 数量入力なしエラーメッセージ出力領域 -->
  <p-messages
    severity="error"
    *ngIf="noOrderNumErrorMessage.outputErrorMessageFlag"
  >
    <!-- 数量入力が1件も存在しない場合、表示 -->
    <ng-template pTemplate>
      <div class="error-message-box p-d-flex p-jc-between">
        <div class="p-message-detail p-d-flex p-flex-column">
          {{ noOrderNumErrorMessage.errorMessage }}
        </div>
      </div>
    </ng-template>
  </p-messages>

  <!-- 入力エラーメッセージ出力領域 -->
  <p-messages severity="error" *ngIf="inputErrorMessageList.length">
    <!-- 入力エラーメッセージが存在する場合、表示 -->
    <ng-template pTemplate>
      <div class="error-message-box p-d-flex p-jc-between">
        <div class="p-message-detail p-d-flex p-flex-column">
          <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
          <div *ngFor="let inputErrorMessage of inputErrorMessageList">
            <!-- 販促資材名称 -->
            {{ inputErrorMessage._columnName }}：
            <!-- 販促資材ID -->
            {{ inputErrorMessage._columnId }}
            <br />
            <!-- エラーメッセージリスト分ループ-->
            <div
              *ngFor="let errorMessage of inputErrorMessage._errorMessageList"
            >
              <!-- カラム名称 -->
              {{ errorMessage._columnName }}：
              <!-- エラーメッセージ -->
              {{ errorMessage._message }}
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>
    </ng-template>
  </p-messages>

  <!-- 入力フォーム -->
  <form [formGroup]="inputForm">
    <!-- 一覧画面表示領域-->
    <p-table
      [value]="searchResultsList"
      [paginator]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
      [rowsPerPageOptions]="[10, 50, 100]"
      [(first)]="pages"
      styleClass="p-datatable-striped order-datatable"
      selectionMode="single"
    >
      <ng-template pTemplate="caption">
        <div class="p-d-flex p-jc-between">
          <div class="p-d-flex">
            <!-- 検索画面表示-->
            <button
              type="button"
              pButton
              icon="pi pi-search"
              (click)="search.toggle()"
              class="p-mr-2"
              pTooltip="検索"
              tooltipPosition="bottom"
            ></button>
            <!-- 注文-->
            <button
              type="button"
              pButton
              label="注文"
              (click)="checkOrder()"
              class="p-mr-2 orderButton"
            ></button>
          </div>
          <div>
            <!-- 全件csv出力-->
            <button
              type="button"
              pButton
              label="CSV出力"
              (click)="exportCSV()"
              class="p-mr-2 exportCsvButton"
              [disabled]="csvLoading"
            ></button>
          </div>
        </div>
      </ng-template>

      <!-- 一覧画面ヘッダー表示-->
      <ng-template pTemplate="header">
        <tr class="p-col-2">
          <!-- APIヘッダーの表示 -->
          <ng-container *ngFor="let column of columnOrder">
            <th [pSortableColumn]="column.field">
              {{ column.header }}
              <p-sortIcon [field]="column.field"></p-sortIcon>
            </th>
          </ng-container>
          <!-- API入力項目ヘッダーの表示 -->
          <ng-container *ngFor="let inputColumn of inputColumnOrder">
            <th class="inputColumnHeader">
              {{ inputColumn.header }}
            </th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-searchResults>
        <tr>
          <!-- 一覧表示-->
          <ng-container *ngFor="let column of columnOrder; index as i">
            <!-- 画像表示 -->
            <td *ngIf="0 == i">
              <img [src]="searchResults[column.field]" alt="image" />
            </td>
            <!-- 一覧項目値表示(詳細画面遷移項目) -->
            <td
              class="transitionDetail"
              (click)="detail(searchResults[column.field])"
              *ngIf="1 == i"
            >
              <label>
                <u>{{ searchResults[column.field] }}</u>
              </label>
            </td>
            <!-- 一覧項目値表示 -->
            <td *ngIf="2 <= i" [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-container>

          <!-- 入力項目表示 -->
          <ng-container *ngFor="let inputItem of inputItemList">
            <!-- 入力項目タイプを判定 -->
            <!-- テキスト -->
            <td *ngIf="inputItem.input_type == 'text'">
              <input
                [name]="inputItem.column_id"
                type="text"
                [maxlength]="inputItem.column_hsize"
                [class.errorItem]="
                  checkErrorItem(
                    searchResults['item_code'],
                    inputItem.column_id
                  )
                "
                [formControlName]="
                  inputItem.column_id + '_' + searchResults['item_code']
                "
                pInputText
              />
            </td>
            <!-- 数字 -->
            <td *ngIf="inputItem.input_type == 'number'">
              <input
                [name]="inputItem.column_id"
                type="number"
                [class.errorItem]="
                  checkErrorItem(
                    searchResults['item_code'],
                    inputItem.column_id
                  )
                "
                [formControlName]="
                  inputItem.column_id + '_' + searchResults['item_code']
                "
                pInputText
              />
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult($event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 入力確認ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- CSV出力 -->
<app-export-file></app-export-file>
