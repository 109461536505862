import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  API_URL_ORDER_ITEM,
  API_URL_SEARCH_ORDER_ITEM,
  API_URL_INPUT_ORDER_ITEM,
  API_URL_ORDER,
} from 'manager/http-constants_key';
import { ORDER_REGISTER_TEMPLATE } from 'manager/template-constant';
import { ConfirmationService } from 'primeng/api';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import {
  HEADER_LIST_API_CONSTANT,
  API_RESPONSE,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ORDER_REGISTER_CONSTANT, ADD_ORDER_API_CONSTANT } from './constant';
import {
  ErrorMessage,
  InputErrorMessage,
  OrderRegisterRequest,
} from './order-register';

@Component({
  selector: 'app-order-register',
  templateUrl: './order-register.component.html',
  styleUrls: ['../order.component.scss', './order-register.component.scss'],
})

/**
 * 発注管理_注文登録
 */
export class OrderRegisterComponent implements OnInit {
  // 画面.入力フォーム
  inputForm: FormGroup = new FormGroup(Object());

  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** CSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER_ITEM;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = ORDER_REGISTER_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧出力順番格納先
  inputColumnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面.入力項目生成
  inputItemList: any[] = new Array();

  // 数量入力なし_エラーメッセージ(画面表示情報)
  noOrderNumErrorMessage = {
    outputErrorMessageFlag: false,
    errorMessage: this.commonService.msg(MESSAGE_CODE.E00014),
  };

  // 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)
  inputErrorMessageList: InputErrorMessage[] = new Array();
  // ページ数
  pages: number = 0;

  /* csv用プロパティ */
  // ヘッダー情報格納先(CSV用)
  csvColumnOrder: any[] = new Array();

  // 検索結果一覧格納先(CSV用)
  csvList: any[] = new Array();

  // CSVダウンロード可能フラグ
  csvLoading: boolean;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private loadingState: LoadingState,
    private messageData: MessageData
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(ORDER_REGISTER_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    /* 入力項目ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(ORDER_REGISTER_TEMPLATE.INPUT_TEMPLATE_ID)
      .subscribe((response) => {
        this.inputColumnOrder = response.body;
      });

    /* ヘッダー情報取得処理(CSV用) */
    this.dbOperationService
      .getHeaderList(ORDER_REGISTER_TEMPLATE.CSV_TEMPLATE_ID)
      .subscribe((response) => {
        this.csvColumnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = [];

    /* 注文登録一覧取得処理(CSV用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_ITEM,
        ORDER_REGISTER_TEMPLATE.CSV_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // 注文登録の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 注文登録一覧情報のJSONをオブジェクトに格納する
          this.csvList = response.body;
        } else {
          this.csvList = [];
        }

        // CSVダウンロードフラグをOFF(ロード終了)
        this.csvLoading = false;
      });

    /* 注文登録一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_ORDER_ITEM,
        ORDER_REGISTER_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((responseSearchResults) => {
        // 注文登録の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(responseSearchResults)) {
          // 注文登録の一覧情報が存在しない場合

          // 空配列をオブジェクトに格納する
          this.searchResultsList = [];

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();

          // 処理を終了
          return;
        }

        // 注文登録入力項目取得処理
        this.dbOperationService
          .getData(
            API_URL_INPUT_ORDER_ITEM,
            ORDER_REGISTER_TEMPLATE.INPUT_TEMPLATE_ID
          )
          .subscribe((responseInputItem) => {
            // 注文登録入力項目が存在するか否か
            if (this.commonService.checkNoneResponse(responseInputItem)) {
              // 注文登録入力項目が存在しない場合

              // 空配列をオブジェクトに格納する
              this.inputItemList = [];

              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // 処理を終了
              return;
            }

            // 画面.入力フォーム情報を一時保存
            const saveInputForm: FormGroup = this.inputForm;

            // 画面.入力フォームを初期化
            this.inputForm = new FormGroup(Object());

            // 入力フォーム用コントロール作成
            // 注文登録一覧情報分ループ
            for (const searchResults of responseSearchResults.body) {
              // 注文登録入力項目分ループ
              for (const input of responseInputItem.body) {
                // 入力フォームグループに入力フォームコントロールを追加

                // フォームコントール名を作成
                const formControlName =
                  input.column_id + CONSTANT.UNDERBAR + searchResults.item_code;

                // フォームコントロールを追加
                this.inputForm.addControl(
                  formControlName,
                  new FormControl(CONSTANT.EMPTY_STRING, {})
                );

                // 一時保存したフォームコントロールが存在するか否か
                if (saveInputForm.get(formControlName)) {
                  // フォームコントロールが存在する場合

                  // 入力フォームに一時保存した値を再セット
                  this.inputForm
                    .get(formControlName)
                    .setValue(saveInputForm.get(formControlName).value);
                }
              }
            }

            // 注文登録一覧情報のJSONをオブジェクトに格納する
            this.searchResultsList = responseSearchResults.body;

            // 注文登録入力項目のJSONをオブジェクトに格納する
            this.inputItemList = responseInputItem.body;

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadSleepEnd(0.3);
          });
      });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER_ITEM;
    inputGenerateDisplay.templateId =
      ORDER_REGISTER_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 注文確認
   */
  protected checkOrder() {
    // 数量入力なし_エラーメッセージを表示フラグをOFFにする
    this.noOrderNumErrorMessage.outputErrorMessageFlag = false;

    // 入力エラーメッセージ出力オブジェクトリストを初期化
    this.inputErrorMessageList = [];

    /* 登録用リクエストオブジェクト作成 */
    {
      // 登録用リクエストオブジェクトを生成
      var orderRegisterRequest: OrderRegisterRequest = new OrderRegisterRequest();

      // 入力フォームオブジェクトリストを生成
      let orderRegisterFormList: object[] = new Array();
      // 注文登録一覧情報分ループ
      for (const searchResults of this.searchResultsList) {
        // 入力フォームオブジェクトを生成
        let orderRegisterForm: object = new Object();

        // 販促資材コードを格納
        orderRegisterForm[ADD_ORDER_API_CONSTANT.ITEM_CODE] =
          searchResults.item_code;

        // 販促資材名称を格納
        orderRegisterForm[ADD_ORDER_API_CONSTANT.ITEM_NAME] =
          searchResults.item_name;

        // 注文登録入力項目分ループ
        for (const inputItem of this.inputItemList) {
          // form情報をオブジェクトに格納
          orderRegisterForm[inputItem.column_id] = this.inputForm.get(
            inputItem.column_id + CONSTANT.UNDERBAR + searchResults.item_code
          ).value;
        }

        // 数量が存在するか否か
        if (!orderRegisterForm[ADD_ORDER_API_CONSTANT.ORDER_NUM]) {
          // 数量が存在しない場合

          continue;
        }

        // 入力フォームオブジェクトをリストに格納
        orderRegisterFormList.push(orderRegisterForm);
      }

      // 登録用リクエストオブジェクトに入力フォームオブジェクトを格納
      orderRegisterRequest.setList = orderRegisterFormList;
    }

    // 入力フォームオブジェクトリストが存在するか否か
    if (!orderRegisterRequest.getList.length) {
      // 入力フォームオブジェクトリストが存在しない場合

      // 数量入力なし_エラーメッセージを表示フラグをONにする
      this.noOrderNumErrorMessage.outputErrorMessageFlag = true;

      return;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00006),
        changeText: '',
      })
    );

    // 入力項目値確認
    this.dbOperationService
      .insertData(API_URL_ORDER, orderRegisterRequest)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // バリデーションチェック状態を確認
        if (API_RESPONSE.FAIL == response.body[0].result) {
          // チェック状態が異常の場合

          // エラーメッセージを編集
          this.createErrorMessage(response.body[0]);

          // 処理を終了
          return;
        }

        // 注文を実施
        this.order(orderRegisterRequest);
      });
  }

  /**
   * 注文
   * @param orderRegisterFormList 登録用リクエストオブジェクト
   */
  protected order(orderRegisterRequest: OrderRegisterRequest) {
    // 登録フラグを格納
    orderRegisterRequest.setRegistFlag = 1;

    // 注文確認ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.I00004),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.I00011),
            changeText: '',
          })
        );

        // 注文登録を実施
        this.dbOperationService
          .insertData(API_URL_ORDER, orderRegisterRequest)
          .subscribe((response) => {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd();

            // レスポンスを判定
            if (
              this.messageData.resultResponseToastMessage(
                response,
                null,
                this.commonService.msg(MESSAGE_CODE.I00005)
              )
            ) {
              // レスポンスが正常終了の場合

              // レスポンスが正常終了の場合
              // sleep時間を設定(0.3秒)
              const sleep = of('').pipe(delay(300));
              // sleep時間後処理を実施
              sleep.subscribe(() => {
                // 注文履歴画面へ遷移
                this.router.navigate(['pages/order/orderHistory2']);
              });
            }
          });
      },
    });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ORDER_REGISTER_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME);

    // ヘッダー情報を作成
    const header = this.commonService.createArrayGetArrayObject(
      this.csvColumnOrder,
      HEADER_LIST_API_CONSTANT.HEADER
    );

    // CSVファイル出力
    this.exportFileComponent.exportCsv(fileName, header, this.csvList);
  }

  /**
   * エラーメッセージを編集
   * @param errorMessage APIから返却されたエラーメッセージ
   */
  private createErrorMessage(errorMessage: any): void {
    //  画面検索結果一覧分ループ
    for (const searchResults of this.searchResultsList) {
      // 販促資材IDに紐づくエラーメッセージを取得
      const applicableErrorMessageList = errorMessage.error.filter(
        (val) =>
          val[ADD_ORDER_API_CONSTANT.ITEM_CODE] ==
          searchResults[ADD_ORDER_API_CONSTANT.ITEM_CODE]
      );

      // 販促資材IDに紐づくエラーメッセージが存在するか否か
      if (!applicableErrorMessageList.length) {
        // エラーメッセージが存在しない場合
        continue;
      }

      // 入力フォームエラー情報オブジェクト生成
      let inputErrorMessage: InputErrorMessage = new InputErrorMessage();

      // 販促資材名称をセット
      inputErrorMessage._columnName = this.commonService.getArrayObjectValue(
        this.columnOrder,
        HEADER_LIST_API_CONSTANT.FIELD,
        HEADER_LIST_API_CONSTANT.HEADER,
        ADD_ORDER_API_CONSTANT.ITEM_CODE
      );

      // 販促資材IDをセット
      inputErrorMessage._columnId =
        searchResults[ADD_ORDER_API_CONSTANT.ITEM_CODE];

      // エラーメッセージリスト生成
      let errorMessageList: ErrorMessage[] = new Array();

      // 販促資材IDに紐づくエラーメッセージでループ
      for (const applicableErrorMessage of applicableErrorMessageList) {
        // エラーメッセージオブジェクト生成
        let errorMessage: ErrorMessage = new ErrorMessage();

        // カラムIDをセット
        errorMessage._columnId =
          applicableErrorMessage[ADD_ORDER_API_CONSTANT.COLUMN_ID];

        // カラム名称をセット
        errorMessage._columnName = this.commonService.getArrayObjectValue(
          this.inputColumnOrder,
          HEADER_LIST_API_CONSTANT.FIELD,
          HEADER_LIST_API_CONSTANT.HEADER,
          applicableErrorMessage[ADD_ORDER_API_CONSTANT.COLUMN_ID]
        );

        // エラーメッセージをセット
        errorMessage._message =
          applicableErrorMessage[ADD_ORDER_API_CONSTANT.MESSAGE];

        // エラーメッセージリストに格納
        errorMessageList.push(errorMessage);
      }

      // 入力フォームエラー情報オブジェクトにエラーメッセージリストをセット
      inputErrorMessage._errorMessageList = errorMessageList;

      // 入力フォームエラー情報オブジェクトをリストに格納
      this.inputErrorMessageList.push(inputErrorMessage);
    }
  }

  /**
   * エラー項目チェック
   * @param pkeyId 販促資材コード
   * @param column_id 対象項目
   * @returns true:エラーメッセージが存在する場合 false:エラーメッセージが存在しない場合
   */
  protected checkErrorItem(pkeyId: string, column_id: string): boolean {
    // 入力エラーメッセージ出力オブジェクトリスト分ループ
    for (const inputErrorMessage of this.inputErrorMessageList) {
      // 入力エラーメッセージ出力オブジェクトの販促資材IDと引数.販促資材コードが一致するか否か
      if (pkeyId == inputErrorMessage._columnId) {
        // 条件が一致する場合

        // エラーメッセージリストのエラーメッセージ存在チェック
        // 対象項目が存在する場合、trueを返却
        return inputErrorMessage._errorMessageList.some(
          (errorMessage) =>
            errorMessage[ADD_ORDER_API_CONSTANT.COLUMN_ID] === column_id
        );
      }
    }

    return false;
  }
}
