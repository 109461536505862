import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { OrderHistory2StageService } from './order-history-2-stage.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import {
  API_URL_ORDER,
  API_URL_INPUT_ORDER,
  API_URL_SEARCH_ORDER,
  API_URL_ORDER_CANCEL,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { ORDER_PERFORMANCE_CONSTANT } from './constant';
import { ORDER_STATUS } from 'src/app/shared/constant/db-constant';
import { ORDER_HISTORY_2_STAGE_TEMPLATE } from 'manager/template-constant';
import { DEPARTMENT_TYPE } from 'src/app/shared/constant/db-constant';
import { UpdateStatus2StageComponent } from './update-status-2-stage/update-status-2-stage.component';
import { HEADER_LIST_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-order-history-2-stage',
  templateUrl: './order-history-2-stage.component.html',
  styleUrls: ['../order.component.scss'],
})

/**
 * 発注実績一覧画面(2段階認証)
 */
export class OrderHistory2StageComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 更新画面ダイアログ */
  @ViewChild(UpdateStatus2StageComponent)
  updateStatus2StageComponent: UpdateStatus2StageComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  /** CSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ORDER;

  /* テンプレート設定(組織種別によって使用テンプレートが変動) */
  // 検索項目生成テンプレートID
  searchTemplateId: number;

  // 一覧項目テンプレートID
  searchResultsTemplateId: number;

  // 表示項目生成テンプレートID
  outputTemplateId: number;

  // CSV出力テンプレートID
  csvTemplateId: number;

  /* 画面用プロパティ */
  // 注文ステータス格納先
  searchStatusList: any[] = new Array();

  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // ページ数
  pages: number = 0;

  /* csv用プロパティ */
  // ヘッダー情報格納先(CSV用)
  csvColumnOrder: any[] = new Array();

  // 検索結果一覧格納先(CSV用)
  csvList: any[] = new Array();

  // CSVダウンロード可能フラグ
  csvLoading: boolean;

  constructor(
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private orderHistory2StageService: OrderHistory2StageService
  ) {}

  ngOnInit() {
    /* テンプレート設定 */
    // ログインユーザの組織種別を判定
    if (DEPARTMENT_TYPE.TYPE_DESTINATION == this.loginUser.department_type) {
      // 組織種別が2:発注担当の場合

      //  依頼者検索項目生成テンプレートIDを設定
      this.searchTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.REQUESTER_SEARCH_TEMPLATE_ID;

      // 依頼者一覧項目テンプレートIDを設定
      this.searchResultsTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.REQUESTER_SEARCH_RESULTS_TEMPLATE_ID;

      // 依頼者表示項目生成テンプレートIDを設定
      this.outputTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.REQUESTER_OUTPUT_TEMPLATE_ID;

      // 依頼者CSV出力テンプレートIDを設定
      this.csvTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.REQUESTER_CSV_TEMPLATE_ID;
    } else {
      // 組織種別が1:KADOKAWA（承認者）,3:事務局,9:他の場合

      //  管理者・承認者検索項目生成テンプレートIDを設定
      this.searchTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.ADMINISTRATOR_SEARCH_TEMPLATE_ID;

      // 管理者・承認者一覧項目テンプレートIDを設定
      this.searchResultsTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.ADMINISTRATOR_SEARCH_RESULTS_TEMPLATE_ID;

      // 管理者・承認者表示項目生成テンプレートIDを設定
      this.outputTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.ADMINISTRATOR_OUTPUT_TEMPLATE_ID;

      // 管理者・承認者CSV出力テンプレートIDを設定
      this.csvTemplateId =
        ORDER_HISTORY_2_STAGE_TEMPLATE.ADMINISTRATOR_CSV_TEMPLATE_ID;
    }

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(this.searchResultsTemplateId)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    /* ヘッダー情報取得処理(CSV用) */
    this.dbOperationService
      .getHeaderList(this.csvTemplateId)
      .subscribe((response) => {
        this.csvColumnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );
    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 発注実績ステータス一覧取得処理 */
    this.orderHistory2StageService.searchStatusList().subscribe((response) => {
      // コード値の一覧情報が存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        this.searchStatusList = [];
        this.searchResultsList = [];
        this.csvList = [];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 処理を終了
        return;
      }

      // 発注実績一覧情報のJSONをオブジェクトに格納する
      this.searchStatusList = response.body;

      /* 発注実績一覧取得処理(画面用) */
      this.dbOperationService
        .getForkJoinData(
          ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
          API_URL_ORDER,
          this.searchResultsTemplateId,
          generateSearchItems
        )
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 発注実績一覧情報のJSONをオブジェクトに格納する
            this.searchResultsList = this.setStatusKey(response.body);
          } else {
            this.searchResultsList = [];
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3);
        });

      /* 発注実績一覧取得処理(CSV用) */
      this.dbOperationService
        .getForkJoinData(
          ORDER_PERFORMANCE_CONSTANT.TARGET_TABLE,
          API_URL_ORDER, this.csvTemplateId, generateSearchItems)
        .subscribe((response) => {
          // コード値の一覧情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // 発注実績一覧情報のJSONをオブジェクトに格納する
            this.csvList = response.body;
          } else {
            this.csvList = [];
          }

          // CSVダウンロードフラグをOFF(ロード終了)
          this.csvLoading = false;
        });
    });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 発注実績詳細
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER;
    inputGenerateDisplay.templateId = this.outputTemplateId;

    // 詳細画面表示
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 更新画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param statusKey 発注ステータス
   */
  protected update(pkeyId: string, statusKey: Number) {
    // 更新画面表示
    this.updateStatus2StageComponent.initial(pkeyId, statusKey);
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected edit(pkeyId: string) {
    let department_type
    // ログインユーザの組織種別を判定
    if (DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type) {
      department_type = ORDER_HISTORY_2_STAGE_TEMPLATE.ADMINISTRATOR_INPUT_TEMPLATE_ID
    } else {
      department_type = ORDER_HISTORY_2_STAGE_TEMPLATE.REQUESTER_INPUT_TEMPLATE_ID
    }
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_ORDER,
      API_URL_ORDER,
      department_type,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete(pkeyId: string) {
    // 更新情報
    let updateValue = Object();

    // 発注ステータスにキャンセルをセット
    updateValue[ORDER_PERFORMANCE_CONSTANT.STATUS] = ORDER_STATUS.STATUS_NINE;

    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.cancelDialog(
      API_URL_ORDER_CANCEL,
      this.columnOrder[0].header,
      pkeyId,
      updateValue
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );
    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 発注実績ステータス一覧取得処理 */
    this.orderHistory2StageService.searchStatusList().subscribe((response) => {
      // コード値の一覧情報が存在するか否か
      if (this.commonService.checkNoneResponse(response)) {
        this.searchStatusList = [];
        this.searchResultsList = [];
        this.csvList = [];

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // 処理を終了
        return;
      }

      // 発注実績一覧情報のJSONをオブジェクトに格納する
      this.searchStatusList = response.body;

      /* 発注実績一覧取得処理(画面用) */
      this.dbOperationService
        .getSingleData(
          API_URL_ORDER,
          this.searchResultsTemplateId,
          this.columnOrder[0].field,
          pkeyId
        )
        .subscribe((response) => {
          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 発注実績一覧情報に 注文ステータスキーをセットする
          response.body = this.setStatusKey(response.body);

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();
        });

      /* 発注実績一覧取得処理(CSV用) */
      this.dbOperationService
        .getSingleData(
          API_URL_ORDER,
          this.csvTemplateId,
          this.columnOrder[0].field,
          pkeyId
        )
        .subscribe((response) => {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先(CSV用)から対象のデータの行数を抽出
          const indexTargetData = this.csvList.indexOf(
            this.csvList.find((csvResults) => {
              return csvResults[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.csvList.splice(indexTargetData, 1, response.body[0]);

          // CSVダウンロードフラグをOFF(ロード終了)
          this.csvLoading = false;
        });
    });
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ORDER_PERFORMANCE_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // ヘッダー情報を作成
    const header = this.commonService.createArrayGetArrayObject(
      this.csvColumnOrder,
      HEADER_LIST_API_CONSTANT.HEADER
    );

    // CSVファイル出力
    this.exportFileComponent.exportCsv(fileName, header, this.csvList);
  }

  /**
   * 注文ステータスキーのセット処理
   * @param data 注文ステータスキーをセットするオブジェクト
   */
  private setStatusKey(data: string[]): string[] {
    for (const dataValue of data) {
      // 注文ステータスキーをオブジェクトにセット
      dataValue['statusKey'] = this.commonService.getArrayObjectValue(
        this.searchStatusList,
        ORDER_PERFORMANCE_CONSTANT.ORDER_ID,
        ORDER_PERFORMANCE_CONSTANT.STATUS,
        dataValue[ORDER_PERFORMANCE_CONSTANT.ORDER_ID]
      );
    }

    return data;
  }
}
