<div class="message">
  <!-- メッセージ -->
  <p [innerHTML]="message"></p>

  <!-- ボタンエリア -->
  <div class="buttonArea">
    <button
      class="button"
      type="button"
      label="再ログイン"
      (click)="login()"
      pButton
    ></button>
  </div>
</div>
