import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import {
  API_URL_ITEM,
  API_URL_SEARCH_ITEM,
  API_URL_INPUT_ITEM,
} from 'manager/http-constants_key';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { ITEM_CONSTANT } from './constant';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { ExportFileComponent } from 'src/app/shared/html-parts/export-file/export-file.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { HEADER_LIST_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['../master.component.scss'],
})

/**
 * 販促資材マスタ画面
 */
export class ItemComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(ItemDetailComponent)
  itemDetailComponent: ItemDetailComponent;

  /** 新規登録、編集、複製画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  /** CSV出力 */
  @ViewChild(ExportFileComponent)
  exportFileComponent: ExportFileComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_ITEM;

  // 検索項目生成テンプレートID
  public searchTemplateId: number = ITEM_TEMPLATE.SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // ページ数
  pages: number = 0;

  /* csv用プロパティ */
  // ヘッダー情報格納先(CSV用)
  csvColumnOrder: any[] = new Array();

  // 検索結果一覧格納先(CSV用)
  csvList: any[] = new Array();

  // CSVダウンロード可能フラグ
  csvLoading: boolean;

  constructor(
    public datePipe: DatePipe,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(ITEM_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    /* ヘッダー情報取得処理(CSV用) */
    this.dbOperationService
      .getHeaderList(ITEM_TEMPLATE.CSV_TEMPLATE_ID)
      .subscribe((response) => {
        this.csvColumnOrder = response.body;
      });

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * 検索処理
   * @param generateSearchItems 検索条件
   */
  public searchResult(generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    /* 販促資材マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_ITEM,
        ITEM_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = [];
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

    /* 販促資材マスタ一覧取得処理(CSV用) */
    this.dbOperationService
      .getData(API_URL_ITEM, ITEM_TEMPLATE.CSV_TEMPLATE_ID, generateSearchItems)
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
          this.csvList = response.body;
        } else {
          this.csvList = [];
        }

        // CSVダウンロードフラグをOFF(ロード終了)
        this.csvLoading = false;
      });

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public detail(pkeyId: string) {
    // 詳細画面表示
    this.itemDetailComponent.initial(pkeyId);
  }

  /**
   * 新規登録画面表示
   */
  public new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 編集画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public edit(pkeyId: string) {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 複製画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  public duplicate(pkeyId: string) {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_ITEM,
      API_URL_ITEM,
      ITEM_TEMPLATE.INPUT_TEMPLATE_ID,
      pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  public delete(pkeyId: string) {
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_ITEM,
      this.columnOrder[0].header,
      pkeyId
    );
  }

  /**
   * 再検索処理＆データ置換
   * @param pkeyId コード値
   */
  public searchReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // CSVダウンロードフラグをON(ロード中状態)
    this.csvLoading = true;

    // 返却されたコード値が存在するか否か
    if (null == pkeyId || undefined == pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施
      this.searchResult();

      return;
    }

    /* 販促資材マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getSingleData(
        API_URL_ITEM,
        ITEM_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧から該当データを削除
          this.searchResultsList = this.searchResultsList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.searchResultsList.indexOf(
            this.searchResultsList.find((results) => {
              return results[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList.splice(indexTargetData, 1, response.body[0]);
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });

    /* 販促資材マスタ一覧取得処理(CSV用) */
    this.dbOperationService
      .getSingleData(
        API_URL_ITEM,
        ITEM_TEMPLATE.CSV_TEMPLATE_ID,
        this.columnOrder[0].field,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 一覧情報が存在しない場合

          // 検索結果一覧(CSV用)から該当データを削除
          this.csvList = this.csvList.filter(
            (val) => val[this.columnOrder[0].field] !== pkeyId
          );
        } else {
          // 一覧情報が存在する場合

          // 検索結果一覧格納先(CSV用)から対象のデータの行数を抽出
          const indexTargetData = this.csvList.indexOf(
            this.csvList.find((csvResults) => {
              return csvResults[this.columnOrder[0].field] === pkeyId;
            })
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.csvList.splice(indexTargetData, 1, response.body[0]);
        }

        // CSVダウンロードフラグをOFF(ロード終了)
        this.csvLoading = false;
      });
  }

  /**
   * CSV出力
   */
  public exportCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // ヘッダー情報を作成
    const header = this.commonService.createArrayGetArrayObject(
      this.csvColumnOrder,
      HEADER_LIST_API_CONSTANT.HEADER
    );

    // CSVファイル出力
    this.exportFileComponent.exportCsv(fileName, header, this.csvList);
  }
}
